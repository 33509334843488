import React from 'react';
import {
  Box,
  Text,
  Heading,
  Flex,
  Tooltip,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import AudioPlayer from '../AudioPlayer/AudioPlayer';

const MusicControls = ({
  musicUrl,
  isPlaying,
  currentTime,
  duration,
  volume,
  frequencyData,
  onPlayPause,
  onVolumeChange,
  onSeek,
  onRemoveMusic,
  onOpenMusicModal,
  timeOverLimit,
}) => {

  const borderColor = useColorModeValue('gray.200', 'gunmetal.550');
  const hoverBgColor = useColorModeValue('gray.100', 'gunmetal.500');
  const textColor = useColorModeValue('gray.800', 'white');
  const subTextColor = useColorModeValue('gray.600', 'gray.400');
  const iconHoverBgColor = useColorModeValue('gray.300', 'gray.500');
  const playerBgColor = useColorModeValue('gray.300', '#232C33');
  const controlColor = useColorModeValue('gray.800', 'white');
  const controlHoverColor = useColorModeValue('gray.600', 'gray.300');
  const progressBarBg = useColorModeValue('gray.300', 'gray.600');
  const progressFilledBg = useColorModeValue('blue.500', 'blue.300');
  const boxBorderColor = useColorModeValue('gray.200', '#2F4957');

  if (musicUrl) {
    return (
      <Box position="relative">
        <Text
          textAlign={'center'}
          fontSize="sm"
          fontWeight="bold"
          mb={2}
        >
          Music
        </Text>
        <AudioPlayer
          isPlaying={isPlaying}
          currentTime={currentTime}
          duration={duration}
          volume={volume}
          frequencyData={frequencyData}
          onPlayPause={onPlayPause}
          onVolumeChange={onVolumeChange}
          onSeek={onSeek}
        />
        <IconButton
          icon={<CloseIcon />}
          aria-label="Remove music"
          position="absolute"
          top="2"
          right="2"
          size="sm"
          onClick={onRemoveMusic}
          bg={'beige.300'}
          _hover={{ bg: 'red.400' }}
        />
      </Box>
    );
  } else {
    return (
      <Tooltip
        label="Duration exceeds the target limit. Please adjust your script."
        isDisabled={!timeOverLimit}
        shouldWrapChildren
      >
        <Flex
          bg={'transparent'}
          flexDirection={'column'}
          p={4}
          align={'center'}
          justify={'center'}
          borderRadius="md"
          borderColor={timeOverLimit ? 'gray.400' : borderColor}
          borderWidth="1px"
          borderStyle={'dashed'}
          transition={'all 0.3s'}
          onClick={!timeOverLimit ? onOpenMusicModal : undefined}
          _hover={{
            bg: !timeOverLimit ? hoverBgColor : 'transparent',
            cursor: timeOverLimit ? 'not-allowed' : 'pointer',
            borderColor: timeOverLimit ? 'gray.400' : borderColor,
            borderWidth: '1px',
            borderStyle: 'solid',
          }}
          opacity={timeOverLimit ? 0.6 : 1}
        >
        <>
          <Heading size="md" color={textColor}>
            Music Selection
          </Heading>
          <Text textAlign="center" color={subTextColor}>
              Costs 10 credits
            </Text>
          </>
        </Flex>
      </Tooltip>
    );
  }
};

export default MusicControls;