import React from 'react';
import {
  Flex,
  Editable,
  EditablePreview,
  Tooltip,
  EditableTextarea,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import EditableControls from '../EditableControls/EditableControls';
import { durationToSMPTE, ensureProperSpacing } from '../../utils/app.utils';

const ShotBlock = ({
  shot,
  onDialogueSubmit,
  onRemoveShot,
  FPS,
  SPEED,
}) => {
  const cardBgColor = useColorModeValue('white', 'gunmetal.600');
  const borderColor = useColorModeValue('gray.200', 'gunmetal.550');
  const hoverBgColor = useColorModeValue('gray.100', 'gunmetal.500');
  const subTextColor = useColorModeValue('gray.600', 'gray.400');
  const toast = useToast();

  const dialogue = shot.audio
    .filter(item => item.type === 'DIALOGUE')
    .map(item => item.content)
    .join(' ');

  const words = dialogue.split(' ').length;
  const estimatedDuration = durationToSMPTE(words, FPS, SPEED);

  const handleSubmit = value => {
    if (typeof value !== 'string') {
      console.error('Expected a string value, but received:', value);
      return;
    }
    onDialogueSubmit(value, shot._id);
  };

  return (
    <Flex
      key={shot._id}
      p={6}
      borderRadius="md"
      boxShadow="lg"
      bg={cardBgColor}
      border="1px solid"
      borderColor={borderColor}
      shadow={'md'}
      mb={6}
      position="relative"
      direction="column"
    >
      <Editable
        defaultValue={ensureProperSpacing(dialogue)}
        onSubmit={handleSubmit}
        placeholder="Add your Dialogue"
        fontSize={'lg'}
        fontWeight={'normal'}
        w="full"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tooltip label="Click to edit" aria-label="Click to edit">
          <EditablePreview
            py={2}
            px={4}
            _hover={{ bg: hoverBgColor }}
            mr={4}
          />
        </Tooltip>

        <EditableTextarea py={2} px={4} flex={1} mr={2} />
        <EditableControls
          onDelete={() => onRemoveShot(shot._id)}
        />
      </Editable>

      <Text
        fontSize="sm"
        color={subTextColor}
        position="absolute"
        top="-20px"
        right="10px"
      >
        Estimated Duration: {estimatedDuration}
      </Text>
    </Flex>
  );
};

export default ShotBlock;
