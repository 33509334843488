import React, { useState, useCallback } from 'react';
import { createProject as createProjectService } from '../services/project.service';
import { useUserContext } from '../providers/UserProvider';
import { useToast } from '@chakra-ui/react';

const useCreateProject = () => {
  const { user, token } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const createProject = useCallback(async (projectTitle) => {
    setIsLoading(true);

    try {
      const newProject = await createProjectService(user._id, projectTitle, token);

      toast({
        title: 'Project added.',
        description: `Project has been added.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setIsLoading(false);

      return newProject;
    } catch (error) {
      console.error('Error creating project:', error);
      setIsLoading(false);

      toast({
        title: 'Error adding project.',
        description: error.message || 'Something went wrong.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });

      return null;
    }
  }, [user._id, token, toast]);

  return { createProject, isLoading };
};

export default useCreateProject;