import { useState, useEffect } from 'react';
import { getProducts } from '../services/product.service';
import { useUserContext } from '../providers/UserProvider';

const useGetProducts = () => {
  const { token } = useUserContext();
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [errorProducts, setErrorProducts] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsData = await getProducts(token);

        // Map the products to ensure they have the necessary fields
        const mappedProducts = productsData.map((product) => ({
          _id: product._id,
          name: product.name,
          description: product.description,
          stripeProductId: product.stripeProductId,
          credits: product.credits,
          prices: product.prices,
          recurring: product.recurring,
          productType: product.productType,
        }));

        const filteredCreditPacks = mappedProducts.filter((product) => product.productType === 'credit_pack');

        const sortedProducts = filteredCreditPacks.sort((a, b) => a.credits - b.credits);

        console.log("sortedProducts", sortedProducts);

        setProducts(sortedProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
        setErrorProducts(error);
      } finally {
        setLoadingProducts(false);
      }
    };

    fetchProducts();
  }, []);

  return { products, loadingProducts, errorProducts };
};

export default useGetProducts;
