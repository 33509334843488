import React, { useState, useEffect } from 'react';
import {
  Avatar,
  AvatarBadge,
  Spinner,
  useBreakpointValue,
  Flex,
  Badge,
  VStack,
  Tooltip,
} from '@chakra-ui/react';
import { useUserContext } from '../../providers/UserProvider';
import LevelProgress from '../LevelProgress/LevelProgress';

const UserInfo = () => {
  const { user, loading } = useUserContext();
  const avatarSize = useBreakpointValue({ base: 'md', md: 'md' });

  const [avatarName, setAvatarName] = useState('User');
  const [avatarImg, setAvatarImg] = useState(null);

  useEffect(() => {
    if (user) {
      setAvatarName(user.email);
      setAvatarImg(user.picture);
    }
  }, [user]);

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" flex={2}>
        <Spinner />
      </Flex>
    );
  }

  return (
    <Flex alignItems="center" flex={2} justifyContent={'flex-end'} mr={8}>

      <Tooltip label={avatarName} aria-label="User">
        <Avatar
          name={avatarName}
          src={avatarImg}
          size={avatarSize}
          bg={!avatarImg ? 'moonstone.500' : undefined}
          mr={4}
          border={'2px solid #375566'}
        >
          <AvatarBadge
            boxSize="1em"
            bg={
              user && user.subscriptionStatus === 'active'
                ? 'green.500'
                : 'red.500'
            }
          />
        </Avatar>
      </Tooltip>

      <Flex>
        {user ? (
          <LevelProgress
        level={user?.level}
        xp={user?.xp}
        xpForNextLevel={user?.xpForNextLevel}
      />
        ) : null}
      </Flex>
    </Flex>
  );
};

export default UserInfo;
