import React, { useState, useEffect } from 'react';
import { useUserContext } from '../providers/UserProvider';
import {
  getCategories,
  startTriviaSession,
  getQuizById,
  answerTriviaQuestion,
  completeTriviaSession,
  getQuizzes,
} from '../services/trivia.service';
import TriviaCategories from '../components/Trivia/TriviaCategories';
import TriviaLayout from '../components/Trivia/TriviaLayout';
import TriviaEnd from '../components/Trivia/TriviaEnd';
import { Spinner, Box, Button } from '@chakra-ui/react';

const Trivia = () => {
  const { user, token } = useUserContext();
  const [categories, setCategories] = useState([]);
  const [currentQuiz, setCurrentQuiz] = useState(null);
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const data = await getCategories(token);
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [token]);

  const handleCategorySelect = async (categoryId) => {
    setLoading(true);
    try {
      const quizzes = await getQuizzes(token);
      const filteredQuizzes = quizzes.filter(
        (quiz) => quiz.category._id === categoryId
      );
      const randomQuiz =
        filteredQuizzes[Math.floor(Math.random() * filteredQuizzes.length)];
      const quiz = await getQuizById(randomQuiz._id, token);
      console.log('Selected quiz:', quiz);
      setCurrentQuiz(quiz);
    } catch (error) {
      console.error('Error starting quiz:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleStartSession = async () => {
    if (!currentQuiz) return;
    setLoading(true);
    try {
      const newSession = await startTriviaSession(currentQuiz._id, user._id, token);
      setSession(newSession);
    } catch (error) {
      console.error('Error starting session:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAnswer = async (questionId, answer) => {
    if (!session) return;
    
    console.log('Session in Trivia:', session);
    console.log('Current Quiz:', currentQuiz);

    try {
      const { correct } = await answerTriviaQuestion(session._id, questionId, answer, token);
      console.log('Correct:', correct);
      if (correct) {
        setSession((prevSession) => ({
          ...prevSession,
          score: prevSession.score + 1,
          questions: prevSession.questions.map((q) =>
            q.question._id === questionId
              ? { ...q, answeredCorrectly: true }
              : q
          ),
        }));
      }
      return correct;
    } catch (error) {
      console.error('Error answering question:', error);
      return false;
    }
  };

  const handleCompleteSession = async () => {
    if (!session) return;
    setLoading(true);
    try {
      const completedSession = await completeTriviaSession(session._id, token);
      setSession(completedSession);
    } catch (error) {
      console.error('Error completing session:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (session && session.completedAt) {
    return (
      <TriviaEnd
        totalScore={session.xpEarned}
        totalQuestions={session.questions.length}
        correctAnswers={session.score}
        onReturn={() => setSession(null)}
      />
    );
  }

  if (session) {
    return (
      <TriviaLayout
        questions={session.questions.map((q) => q.question)}
        xp={session.xpEarned}
        xpForQuiz={currentQuiz.xp}
        onAnswer={handleAnswer}
        onComplete={handleCompleteSession}
      />
    );
  }

  if (currentQuiz) {
    return (
      <Box textAlign="center" p={4}>
        {user?._id ? (
          <Button onClick={handleStartSession}>Start Quiz</Button>
        ) : (
          <Spinner />
        )}
      </Box>
    );
  }

  return (
    <TriviaCategories categories={categories} onSelect={handleCategorySelect} />
  );
};

export default Trivia;
