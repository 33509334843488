import React, { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Flex, Icon, Text, Box } from '@chakra-ui/react';
import { FaStar, FaCreditCard } from 'react-icons/fa';
import { MotionBox } from '../../config/config';

const Sparkle = ({ delay, sparklesColor, size }) => {
  const randomX = (Math.random() - 0.5) * 60;
  const randomY = -(30 + Math.random() * 50);
  const randomDuration = 0.8 + Math.random() * 0.4;

  return (
    <MotionBox
      position='absolute'
      zIndex={1000}
      left='50%'
      top='50%'
      width={size}
      height={size}
      borderRadius='50%'
      bg={sparklesColor}
      initial={{ scale: 0, x: 0, y: 0, opacity: 0 }}
      animate={{
        scale: [0, 1.2, 0.8, 0],
        x: [0, randomX],
        y: [0, randomY],
        opacity: [0, 1, 1, 0],
      }}
      transition={{
        duration: randomDuration,
        delay,
        ease: 'easeOut',
        times: [0, 0.2, 0.8, 1],
      }}
    />
  );
};

const SparkleGroup = ({ count = 3, sparklesColor, size }) => {
  return (
    <Box position="absolute" inset="0">
      {Array.from({ length: count }).map((_, i) => (
        <Sparkle key={i} delay={i * 0.08} sparklesColor={sparklesColor} size={size} />
      ))}
    </Box>
  );
};

const AnimatedIconWithNumber = ({
  icon,
  number,
  duration = 3000,
  onAnimationComplete,
  position = 'fixed',
  size = 'md',
  mainColor,
  sparklesColor,
  bottom,
  right,
  left,
  top,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const IconComponent = icon === 'xp' ? FaStar : FaCreditCard;
  const sizeMapping = {
    xs: {
      icon: '16px',
      text: '1rem',
      sparkle: '2px',
    },
    sm: {
      icon: '24px',
      text: '1.25rem',
      sparkle: '3px',
    },
    md: {
      icon: '32px',
      text: '1.5rem',
      sparkle: '4px',
    },
    lg: {
      icon: '40px',
      text: '1.75rem',
      sparkle: '5px',
    },
    xl: {
      icon: '48px',
      text: '2rem',
      sparkle: '6px',
    },
  };

  const { icon: iconSize, text: textSize, sparkle: sparkleSize } = sizeMapping[size] || sizeMapping.md;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onAnimationComplete?.();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onAnimationComplete]);

  return (
    <AnimatePresence>
      {isVisible && (
        <MotionBox
          position={position}
          bottom={bottom}
          right={right}
          left={left}
          top={top}
          display="flex"
          alignItems="center"
          pointerEvents="none"
          zIndex={2000}
          initial={{ opacity: 0, y: 20, scale: 0.9 }}
          animate={{
            opacity: 1,
            y: -60,
            scale: 1,
            transition: {
              duration: 1.2,
              ease: [0.34, 1.56, 0.64, 1],
              opacity: { duration: 0.4 },
            },
          }}
          exit={{
            opacity: 0,
            scale: 0.9,
            transition: {
              duration: 0.3,
              ease: 'easeInOut',
            },
          }}
        >
          <Flex
            position="relative"
            alignItems="center"
            justifyContent="center"
            width={iconSize}
            height={iconSize}
            mr={2}
          >
            <Icon
              as={IconComponent}
              boxSize={iconSize}
              color={mainColor}
              position="relative"
              zIndex={1001}
            />
            <SparkleGroup count={3} sparklesColor={sparklesColor} size={sparkleSize} />
          </Flex>
          <Flex
            position="relative"
            alignItems="center"
            justifyContent="center"
            minWidth="2rem"
            height={iconSize}
          >
            <Text
              fontSize={textSize}
              fontWeight="bold"
              color={mainColor}
              position="relative"
              zIndex={1001}
              mr={1}
            >
              {icon === 'xp' ? '+' : '-'}
              {number.toString()}
            </Text>
            <Text
              fontSize={textSize}
              fontWeight="bold"
              color={mainColor}
              position="relative"
              zIndex={1001}
            >
              {icon === 'xp' ? ' XP' : ' Credits'}
            </Text>
            <SparkleGroup count={3} sparklesColor={sparklesColor} size={sparkleSize} />
          </Flex>
        </MotionBox>
      )}
    </AnimatePresence>
  );
};

export default AnimatedIconWithNumber;
