import { useState, useEffect } from 'react';
import axios from 'axios';
import { config } from '../config/config';
import { useUserContext } from '../providers/UserProvider';

const useRecentProjects = (limit = 3) => {
  const [recentProjects, setRecentProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { user, token } = useUserContext();

  useEffect(() => {
    const fetchRecentProjects = async () => {
      try {
        if (!user) return;

        const response = await axios.get(`${config.BACKEND_URL}/projects/recent`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            limit,
          },
        });

        const projects = response.data;

        setRecentProjects(projects);
      } catch (err) {
        console.error('Error fetching recent projects:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentProjects();
  }, [user, limit, token]);

  return { recentProjects, loading, error };
};

export default useRecentProjects;
