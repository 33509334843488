import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  HStack,
  Icon,
  Text,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import { BsFiletypeTxt, BsFiletypePdf, BsFiletypeDocx } from 'react-icons/bs';

const exportOptions = [
  { type: 'txt', icon: BsFiletypeTxt, label: 'Export to TXT' },
  { type: 'pdf', icon: BsFiletypePdf, label: 'Export to PDF' },
  { type: 'docx', icon: BsFiletypeDocx, label: 'Export to MS Word' },
];

const ExportModal = ({ isOpen, onClose, onExport, mediaType }) => {
  const bgColor = useColorModeValue('beige.100', '#232C33');
  const headerBgColor = useColorModeValue('beige.200', '#314048');
  const borderColor = useColorModeValue('gray.200', '#3E4E57');
  const textColor = useColorModeValue('gray.800', 'white');
  const buttonBgColor = useColorModeValue('gray.50', '#1C242A');
  const buttonBorderColor = useColorModeValue('gray.200', '#3E4E57');
  const buttonHoverBgColor = useColorModeValue('gray.100', '#314048');
  const buttonHoverBorderColor = useColorModeValue('gray.300', 'beige.400');
  const iconColor = useColorModeValue('yellow.500', 'yellow.400');

  const ExportButton = ({ type, icon, label }) => (
    <Flex
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={2}
      bg={buttonBgColor}
      border="1px solid"
      borderColor={buttonBorderColor}
      borderRadius="md"
      p="40px"
      textAlign="center"
      cursor="pointer"
      transition="all .3s ease-out"
      _hover={{ 
        borderColor: buttonHoverBorderColor, 
        bg: buttonHoverBgColor 
      }}
      onClick={() => handleExport(type)}
    >
      <Icon as={icon} w="24px" h="24px" color={iconColor} />
      <Text color={textColor}>{label}</Text>
    </Flex>
  );

  const handleExport = (type) => {
    onExport(type);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
      <ModalOverlay />
      <ModalContent 
        borderRadius={10} 
        bg={bgColor} 
        color={textColor} 
        overflow="hidden"
        borderColor={borderColor}
        borderWidth="1px"
      >
        <ModalHeader 
          bg={headerBgColor} 
          borderBottomWidth={1}
          borderColor={borderColor}
        >
          Export {mediaType}
        </ModalHeader>
        <ModalCloseButton color={textColor} />
        <ModalBody>
          <HStack spacing={4} justifyContent="center" align="stretch" p={4} w="100%">
            {exportOptions.map(({ type, icon, label }) => (
              <ExportButton
                key={type}
                type={type}
                icon={icon}
                label={label}
              />
            ))}
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ExportModal;
