import api from '../utils/api.utils';
import { handleResponse } from '../utils/app.utils';

// Retrieves projects for a specific user.
export const getProjects = async (userId, token) => {
  const response = await api.get(`/projects`, {
    params: { userId },
    customHeaders: { token },
  });
  return handleResponse(response, 'Error getting projects');
};

// Retrieves a project by its ID.
export const getProjectById = async (projectId, token) => {
  const response = await api.get(`/projects/${projectId}`, {
    customHeaders: { token },
  });
  return handleResponse(response, 'Error getting project');
};

// Creates a new project.
export const createProject = async (userId, projectTitle, token) => {
  const projectData = { userId, projectTitle };
  const response = await api.post(`/projects`, projectData, {
    customHeaders: { token },
  });
  return handleResponse(response, 'Error creating project');
};

// Updates a project with the given project ID and details.
export const updateProject = async (projectId, projectDetails, token) => {
  try {
    const response = await api.put(`/projects/${projectId}`, projectDetails, {
      customHeaders: { token },
    });
    return handleResponse(response, 'Error updating project');
  } catch (error) {
    console.error('Error', error.message);
    throw new Error('Failed to update project');
  }
};

// Deletes a project.
export const deleteProject = async (projectId, token) => {
  const response = await api.delete(`/projects/${projectId}/delete`, {
    customHeaders: { token },
  });
  return handleResponse(response, 'Error deleting project');
};
