import { useState, useEffect } from 'react';
import axios from 'axios';
import { useUserContext } from '../providers/UserProvider';
import { config } from '../config/config';

const useAchievements = () => {
  const [achievements, setAchievements] = useState([]);
  const [userAchievements, setUserAchievements] = useState([]);
  const [loadingAchievements, setLoadingAchievements] = useState(true);
  const { user } = useUserContext();

  useEffect(() => {
    const fetchAchievements = async () => {
      try {
        const response = await axios.get(`${config.BACKEND_URL}/achievements`);
        setAchievements(response.data);
        console.log('All achievements', response.data);
      } catch (error) {
        console.error('Error fetching achievements:', error);
      } finally {
        setLoadingAchievements(false);
      }
    };

    fetchAchievements();
  }, []);

  useEffect(() => {
    if (user && user.achievements) {
      setUserAchievements(user.achievements);
      console.log('User achievements', user.achievements);
    }
  }, [user]);

  return { achievements, userAchievements, loadingAchievements };
};

export default useAchievements;
