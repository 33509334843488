import api from '../utils/api.utils';
import { handleResponse } from '../utils/app.utils';

export const getSongs = async token => {
    try {
        const response = await api.get(`/musics`, { customHeaders: { token } });
        return handleResponse(response, 'Error fetching songs');
    } catch (error) {
        console.error('Error fetching songs:', error.message);
        throw error;
    }
};

export const getSongById = async (id, token) => {
    try {
        const response = await api.get(`/musics/${id}`, { customHeaders: { token } });
        return handleResponse(response, 'Error fetching music by id');
    } catch (error) {
        console.error('Error fetching music by id:', error.message);
        throw error;
    }
};

export const createSong = async (songData, token) => {
    try {
        const response = await api.post(`/musics`, songData, { customHeaders: { token } });
        return handleResponse(response, 'Error creating song');
    } catch (error) {
        console.error('Error creating song:', error.message);
        throw error;
    }
};

export const updateSong = async (id, songData, token) => {
    try {
        const response = await api.put(`/musics/${id}`, songData, { customHeaders: { token } });
        return handleResponse(response, 'Error updating song');
    } catch (error) {
        console.error('Error updating song:', error.message);
        throw error;
    }
};

export const deleteSong = async (id, token) => {
    try {
        const response = await api.delete(`/musics/${id}`, { customHeaders: { token } });
        return handleResponse(response, 'Error deleting song');
    } catch (error) {
        console.error('Error deleting song:', error.message);
        throw error;
    }
};

export const removeSongFromProject = async (projectId, musicId, deleteSong, token) => {
    try {
        const response = await api.post(`/musics/remove-song`, 
            { projectId, musicId, deleteSong }, { customHeaders: { token } });
        return handleResponse(response, 'Error removing song from project');
    } catch (error) {
        console.error('Error removing song from project:', error.message);
        throw error;
    }
};

export const addSongToProject = async (projectId, musicId, token) => {
    try {
        const response = await api.post(`/musics/add-song`, { projectId, musicId }, { customHeaders: { token } });
        return handleResponse(response, 'Error adding song to project');
    } catch (error) {
        console.error('Error adding song to project:', error.message);
        throw error;
    }
};

export const getAvailableSongs = async token => {
    try {
        const response = await api.get(`/musics/available`, { customHeaders: { token } });
        return handleResponse(response, 'Error fetching available songs');
    } catch (error) {
        console.error('Error fetching available songs:', error.message);
        throw error;
    }
};

export const getUserSpecificGeneratedSongs = async (token, userId = null) => {
    try {
        const queryString = userId ? `?userId=${userId}` : '';

        const response = await api.get(`/musics/user-specific-generated${queryString}`, {
            customHeaders: { token },
        });
        
        return handleResponse(response, 'Error fetching user-specific generated songs');
    } catch (error) {
        console.error('Error fetching user-specific generated songs:', error.message);
        throw error;
    }
};


export const getAllUserGeneratedSongs = async token => {
    try {
        const response = await api.get(`/musics/all-user-generated`, { customHeaders: { token } });
        return handleResponse(response, 'Error fetching all user-generated songs');
    } catch (error) {
        console.error('Error fetching all user-generated songs:', error.message);
        throw error;
    }
};