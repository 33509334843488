import { useCallback, useState } from 'react';
import { createCheckoutSession, retrieveCheckoutSession } from '../services/billing.service';
import { useUserContext } from '../providers/UserProvider';

const useStripeCheckout = () => {
  const { token } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState(null);

  const handleStripeCheckout = useCallback(
    async ({ planId, planInterval, products, returnUrl }) => {
      setLoading(true);
      try {
        const response = await createCheckoutSession(
          { planId, planInterval, products, returnUrl },
          token
        );
        return response.url;
      } catch (error) {
        console.error('Error processing Stripe checkout:', error);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  const handleStripeSession = useCallback(
    async (sessionId) => {
      setLoading(true);
      try {
        const session = await retrieveCheckoutSession(sessionId, token);
        setSession(session);

        return session;
      } catch (error) {
        console.error('Error retrieving Stripe session:', error);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  return { handleStripeCheckout, handleStripeSession, loading, session };
};

export default useStripeCheckout;
