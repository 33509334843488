import { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useUserContext } from '../providers/UserProvider';
import { addAudioToProject as addAudioToProjectService } from '../services/audio.service';
import { removeAudioFromProject as removeAudioFromProjectService } from '../services/audio.service';

const useManageVoiceover = () => {
  const { token } = useUserContext();
  const toast = useToast();

  const [manageVoiceoverLoading, setManageVoiceoverLoading] = useState(false);

  const addAudioToProject = async ({ projectId, scriptId, audioData }) => {
    setManageVoiceoverLoading(true);
    
    try {
      const response = await addAudioToProjectService({ projectId, scriptId, audioData, token });
      return response;
    } catch (error) {
      console.error('Error adding audio to project:', error);
      toast({
        title: 'Error',
        description: 'Error adding audio to project',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      throw error;
    } finally {
      setManageVoiceoverLoading(false);
    }
  };

  const removeAudioFromProject = async ({ projectId, scriptId, audioId }) => {
    setManageVoiceoverLoading(true);

    try {
      const response = await removeAudioFromProjectService({ projectId, scriptId, audioId, token });
      return response;
    } catch (error) {
      console.error('Error removing audio from project:', error);
      throw error;
    } finally {
      setManageVoiceoverLoading(false);
    }
  };

  return { addAudioToProject, removeAudioFromProject, manageVoiceoverLoading };
};

export default useManageVoiceover;

