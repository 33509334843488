import {
  ButtonGroup,
  IconButton,
  Flex,
  Editable,
  EditablePreview,
  EditableInput,
  useEditableControls,
  HStack,
} from '@chakra-ui/react';
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';

function EditableControlsInput({ inputValue, setInputValue, handleSubmit }) {

  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
        <IconButton icon={<CloseIcon />} {...getCancelButtonProps()} />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="stretch">
        <IconButton size="sm" icon={<EditIcon />} {...getEditButtonProps()} />
      </Flex>
    );
  }

  return (
    <Editable
      textAlign="center"
      value={inputValue}
      onChange={(value) => setInputValue(value)}
      onSubmit={handleSubmit}
      fontSize="4xl"
      fontWeight={'bold'}
      isPreviewFocusable={false}
    >
      <HStack>
        <EditablePreview mr={4} />
        <EditableInput textAlign="left" />
        <EditableControls />
      </HStack>
    </Editable>
  );
}

export default EditableControlsInput;
