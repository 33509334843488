import { defineStyleConfig } from '@chakra-ui/react';

const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: 'normal',
    textTransform: 'none',
    borderRadius: 'sm',
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      px: 4,
      py: 3,
    },
    md: {
      fontSize: 'md',
      px: 6,
      py: 4,
    },
    lg: {
      fontSize: 'lg',
      px: 12,
      py: 8,
    },
    xl: {
      fontSize: 'xl',
      px: 12,
      py: 8,
    },
    custom: {
      fontSize: 'xl',
      p: 1,
      h: 50,
      w: 50,
    },
  },
  variants: {
    outline: {
      border: '1px solid',
      borderColor: 'white',
      color: 'white',
      borderRadius: 'full',
      _hover: {
        color: 'white',
        backgroundColor: 'gunmetal.450',
      },
    },
    disabled: {
      cursor: 'not-allowed',
      boxShadow: 'none',
      backgroundColor: '#70708A',
      color: '#999',
      borderRadius: 'xl',
      border: '1px transparent',
    },
    naked: {
      bg: 'none',
      borderRadius: 'full',
    },
    filled: (props) => ({
      backgroundColor: props.colorMode === 'light' ? 'lightMode.primary' : 'darkMode.primary',
      color: props.colorMode === 'light' ? 'lightMode.text' : 'darkMode.text',
      borderRadius: 'md',
      _hover: {
        color: props.colorMode === 'light' ? 'lightMode.text' : 'darkMode.text',
        backgroundColor: props.colorMode === 'light' ? 'gunmetal.400' : 'gunmetal.500',
      },
    }),
    bulletUnchecked: (props) => ({
      backgroundColor: 'transparent',
      color: props.colorMode === 'light' ? 'gray.800' : 'white',
      borderRadius: 'full',
      border: '1px solid',
      borderWidth: '1px',
      borderColor: props.colorMode === 'light' ? 'beige.400' : 'white',
      _hover: {
        color: props.colorMode === 'light' ? 'beige.400' : 'white',
        backgroundColor: props.colorMode === 'light' ? 'beige.100' : 'gunmetal.400',
      },
    }),
    bulletChecked: (props) => ({
      backgroundColor: props.colorMode === 'light' ? 'beige.100' : 'beige.400',
      color: props.colorMode === 'light' ? 'gray.800' : 'white',
      border: '1px solid',
      borderColor: 'transparent',
      borderRadius: 'full',
      _hover: {
        color: props.colorMode === 'light' ? 'gray.800' : 'white',
        backgroundColor: props.colorMode === 'light' ? 'beige.100' : 'beige.400',
      },
    }),
  },
  defaultProps: {
    size: 'md',
    variant: 'filled',
  },
});

export default Button;
