import React from 'react';
import {
  Box,
  Heading,
  Text,
  Tabs,
  TabList,
  TabPanels,
  TabIndicator,
  Tab,
  TabPanel,
  VStack,
  HStack,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link as ChakraLink,
  useColorModeValue,
  Divider,
} from '@chakra-ui/react';
import {
  FaFolderPlus,
  FaCog,
  FaMagic,
  FaPlayCircle,
  FaLightbulb,
  FaExclamationCircle,
  FaChevronRight,
} from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';

const Help = () => {
  return (
    <Box maxW="6xl" mx="auto" px={4} py={8}>
      <Heading as="h1" size="2xl" mb={8} textAlign="center">
        Help Center
      </Heading>
      <Tabs variant="unstyled" isFitted>
        <TabList mb="1em">
          <Tab
            _selected={{
              color: 'white',
              bg: useColorModeValue('gunmetal.400', 'gunmetal.500'),
              borderRadius: 'lg',
            }}
          >
            Getting Started
          </Tab>
          <Tab
            _selected={{
              color: 'white',
              bg: useColorModeValue('gunmetal.400', 'gunmetal.500'),
              borderRadius: 'lg',
            }}
          >
            FAQ
          </Tab>
          <Tab
            _selected={{
              color: 'white',
              bg: useColorModeValue('gunmetal.400', 'gunmetal.500'),
              borderRadius: 'lg',
            }}
          >
            Tips
          </Tab>
          <Tab
            _selected={{
              color: 'white',
              bg: useColorModeValue('gunmetal.400', 'gunmetal.500'),
              borderRadius: 'lg',
            }}
          >
            Troubleshooting
          </Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg={useColorModeValue('gunmetal.400', 'gunmetal.500')}
          borderRadius="1px"
        />
        <Divider />
        <TabPanels mt={4}>
          {/* Getting Started Tab */}
          <TabPanel>
            <Box
              borderWidth="1px"
              borderColor={useColorModeValue('gunmetal.400', 'gunmetal.400')}
              borderRadius="lg"
              overflow="hidden"
              p={6}
              bg={useColorModeValue('white', 'gunmetal.700')}
            >
              <VStack align="start" spacing={6}>
                <Box>
                  <Heading size="lg">Getting Started with AI Video Ads</Heading>
                  <Text
                    fontSize="md"
                    color={useColorModeValue('gray.500', 'gray.400')}
                  >
                    Learn how to create your first AI-generated video ad
                  </Text>
                </Box>
                <VStack align="start" spacing={4}>
                  {/* Step 1 */}
                  <HStack align="start" gap={8}>
                    <Box
                      bg={useColorModeValue('gunmetal.400', 'gunmetal.500')}
                      rounded="full"
                      w={10}
                      h={10}
                      p={2}
                      color="white"
                      mt={1}
                    >
                      <Icon as={FaFolderPlus} w={6} h={6} />
                    </Box>
                    <Box>
                      <Text fontWeight="semibold" fontSize="lg">
                        1. Create a New Project
                      </Text>
                      <Text
                        fontSize="sm"
                        color={useColorModeValue('gray.500', 'gray.400')}
                      >
                        Click on the "New Project" button and give your project
                        a name.
                      </Text>
                    </Box>
                  </HStack>
                  {/* Step 2 */}
                  <HStack align="start" gap={8}>
                    <Box
                      bg={useColorModeValue('gunmetal.400', 'gunmetal.500')}
                      rounded="full"
                      w={10}
                      h={10}
                      p={2}
                      color="white"
                      mt={1}
                    >
                      <Icon as={FaCog} w={6} h={6} />
                    </Box>
                    <Box>
                      <Text fontWeight="semibold" fontSize="lg">
                        2. Define Your Ad Parameters
                      </Text>
                      <Text
                        fontSize="sm"
                        color={useColorModeValue('gray.500', 'gray.400')}
                      >
                        Specify your target audience, ad duration, mood, and
                        industry.
                      </Text>
                    </Box>
                  </HStack>
                  {/* Step 3 */}
                  <HStack align="start" gap={8}>
                    <Box
                      bg={useColorModeValue('gunmetal.400', 'gunmetal.500')}
                      rounded="full"
                      w={10}
                      h={10}
                      p={2}
                      color="white"
                      mt={1}
                    >
                      <Icon as={FaMagic} w={6} h={6} />
                    </Box>
                    <Box>
                      <Text fontWeight="semibold" fontSize="lg">
                        3. Generate and Refine
                      </Text>
                      <Text
                        fontSize="sm"
                        color={useColorModeValue('gray.500', 'gray.400')}
                      >
                        Use our AI to generate your ad content, then refine as
                        needed.
                      </Text>
                    </Box>
                  </HStack>
                  {/* Step 4 */}
                  <HStack align="start" gap={8}>
                    <Box
                      bg={useColorModeValue('gunmetal.400', 'gunmetal.500')}
                      rounded="full"
                      w={10}
                      h={10}
                      p={2}
                      color="white"
                      mt={1}
                    >
                      <Icon as={FaPlayCircle} w={6} h={6} />
                    </Box>
                    <Box>
                      <Text fontWeight="semibold" fontSize="lg">
                        4. Preview and Export
                      </Text>
                      <Text
                        fontSize="sm"
                        color={useColorModeValue('gray.500', 'gray.400')}
                      >
                        Review your generated video ad and export it in your
                        desired format.
                      </Text>
                    </Box>
                  </HStack>
                </VStack>
              </VStack>
            </Box>
          </TabPanel>

          {/* FAQ Tab */}
          <TabPanel>
            <Box
              borderWidth="1px"
              borderColor={useColorModeValue('gunmetal.400', 'gunmetal.400')}
              borderRadius="lg"
              overflow="hidden"
              p={6}
              bg={useColorModeValue('white', 'gunmetal.700')}
            >
              <VStack align="start" spacing={6}>
                <Box>
                  <Heading size="lg" fontWeight={'bold'}>Frequently Asked Questions</Heading>
                  <Text
                    fontSize="md"
                    color={useColorModeValue('gray.500', 'gray.400')}
                  >
                    Find answers to common questions about our AI video ad
                    platform
                  </Text>
                </Box>
                <Accordion allowToggle w="full">
                  {/* FAQ Item 1 */}
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          <Text fontWeight={'semibold'} fontSize={'lg'}>How does the AI generate video ads?</Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text fontSize={'md'}>
                        Our AI uses advanced machine learning algorithms to
                        analyze your inputs, including target audience, industry,
                        and mood. It then generates a script, selects appropriate
                        visuals, and combines them with AI-generated voiceovers
                        and music to create a cohesive video ad.
                      </Text>
                    </AccordionPanel>
                  </AccordionItem>
                  {/* FAQ Item 2 */}
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          <Text fontWeight={'semibold'} fontSize={'lg'}>Can I customize the AI-generated content?</Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text fontSize={'md'}>
                        Yes, you can customize all aspects of the AI-generated
                        content. After the initial generation, you can edit the
                        script, change visuals, adjust the voiceover, or modify
                        the background music to better suit your needs.
                      </Text>
                    </AccordionPanel>
                  </AccordionItem>
                  {/* FAQ Item 3 */}
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          <Text fontWeight={'semibold'} fontSize={'lg'}>What file formats are supported for export?</Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text fontSize={'md'}>
                        We support a variety of popular video formats including
                        MP4, MOV, and AVI. You can also export your ad in
                        different resolutions suitable for various social media
                        platforms and websites.
                      </Text>
                    </AccordionPanel>
                  </AccordionItem>
                  {/* FAQ Item 4 */}
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          <Text fontWeight={'semibold'} fontSize={'lg'}>How long does it take to generate a video ad?</Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text fontSize={'md'}>
                        The generation time depends on the complexity and duration
                        of your ad. Typically, a 30-second ad takes about 5-10
                        minutes to generate. However, more complex ads or those
                        requiring multiple iterations may take longer.
                      </Text>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </VStack>
            </Box>
          </TabPanel>

          {/* Tips Tab */}
          <TabPanel>
            <Box
              borderWidth="1px"
              borderColor={useColorModeValue('gunmetal.400', 'gunmetal.400')}
              borderRadius="lg"
              overflow="hidden"
              p={6}
              bg={useColorModeValue('white', 'gunmetal.700')}
            >
              <VStack align="start" spacing={6}>
                <Box>
                  <Heading size="lg" fontWeight={'bold'}>
                    Tips for Creating Effective AI Video Ads
                  </Heading>
                  <Text
                    fontSize="md"
                    color={useColorModeValue('gray.500', 'gray.400')}
                  >
                    Maximize the impact of your AI-generated video ads with
                    these tips
                  </Text>
                </Box>
                <VStack align="start" spacing={4}>
                  {/* Tip 1 */}
                  <HStack align="start" gap={8}>
                    <Box
                      bg={useColorModeValue('gunmetal.400', 'gunmetal.500')}
                      rounded="full"
                      w={10}
                      h={10}
                      p={2}
                      color="white"
                      mt={1}
                    >
                      <Icon as={FaLightbulb} w={6} h={6} />
                    </Box>

                    <Box>
                      <Text fontWeight="semibold" fontSize="lg">
                        Know Your Audience
                      </Text>
                      <Text
                        fontSize="sm"
                        color={useColorModeValue('gray.500', 'gray.400')}
                      >
                        The more specific you are about your target audience,
                        the more tailored and effective your ad will be.
                      </Text>
                    </Box>
                  </HStack>
                  {/* Tip 2 */}
                  <HStack align="start" gap={8}>
                    <Box
                      bg={useColorModeValue('gunmetal.400', 'gunmetal.500')}
                      rounded="full"
                      w={10}
                      h={10}
                      p={2}
                      color="white"
                      mt={1}
                    >
                      <Icon as={FaLightbulb} w={6} h={6} />
                    </Box>
                    <Box>
                      <Text fontWeight="semibold" fontSize="lg">
                        Keep It Concise
                      </Text>
                      <Text
                        fontSize="sm"
                        color={useColorModeValue('gray.500', 'gray.400')}
                      >
                        Shorter ads often perform better. Aim for 15-30 seconds
                        to maintain viewer attention.
                      </Text>
                    </Box>
                  </HStack>
                  {/* Tip 3 */}
                  <HStack align="start" gap={8}>
                    <Box
                      bg={useColorModeValue('gunmetal.400', 'gunmetal.500')}
                      rounded="full"
                      w={10}
                      h={10}
                      p={2}
                      color="white"
                      mt={1}
                    >
                      <Icon as={FaLightbulb} w={6} h={6} />
                    </Box>
                    <Box>
                      <Text fontWeight="semibold" fontSize="lg">
                        Use Emotional Appeal
                      </Text>
                      <Text
                        fontSize="sm"
                        color={useColorModeValue('gray.500', 'gray.400')}
                      >
                        Ads that evoke emotions are more memorable. Choose a
                        mood that resonates with your message and audience.
                      </Text>
                    </Box>
                  </HStack>
                  {/* Tip 4 */}
                  <HStack align="start" gap={8}>
                    <Box
                      bg={useColorModeValue('gunmetal.400', 'gunmetal.500')}
                      rounded="full"
                      w={10}
                      h={10}
                      p={2}
                      color="white"
                      mt={1}
                    >
                      <Icon as={FaLightbulb} w={6} h={6} />
                    </Box>
                    <Box>
                      <Text fontWeight="semibold" fontSize="lg">
                        Include a Clear Call-to-Action
                      </Text>
                      <Text
                        fontSize="sm"
                        color={useColorModeValue('gray.500', 'gray.400')}
                      >
                        Tell viewers what you want them to do after watching
                        your ad. Make it clear and compelling.
                      </Text>
                    </Box>
                  </HStack>
                </VStack>
              </VStack>
            </Box>
          </TabPanel>

          {/* Troubleshooting Tab */}
          <TabPanel>
            <Box
              borderWidth="1px"
              borderColor={useColorModeValue('gunmetal.400', 'gunmetal.400')}
              borderRadius="lg"
              overflow="hidden"
              p={6}
              bg={useColorModeValue('white', 'gunmetal.700')}
            >
              <VStack align="start" spacing={6}>
                <Box>
                  <Heading size="lg">Troubleshooting</Heading>
                  <Text
                    fontSize="md"
                    color={useColorModeValue('gray.500', 'gray.400')}
                  >
                    Solutions to common issues you might encounter
                  </Text>
                </Box>
                <VStack align="start" spacing={4}>
                  {/* Issue 1 */}
                  <HStack align="start" gap={8}>
                    <Box
                      bg={useColorModeValue('gunmetal.400', 'gunmetal.500')}
                      rounded="full"
                      w={10}
                      h={10}
                      p={2}
                      color="white"
                      mt={1}
                    >
                      <Icon as={FaExclamationCircle} w={6} h={6} />{' '}
                    </Box>

                    <Box>
                      <Text fontWeight="semibold" fontSize="lg">
                        Generation Process Is Slow
                      </Text>
                      <Text
                        fontSize="sm"
                        color={useColorModeValue('gray.500', 'gray.400')}
                      >
                        If the generation process is taking too long, try
                        refreshing the page or reducing the complexity of your
                        ad parameters.
                      </Text>
                    </Box>
                  </HStack>
                  {/* Issue 2 */}
                  <HStack align="start" gap={8}>
                    <Box
                      bg={useColorModeValue('gunmetal.400', 'gunmetal.500')}
                      rounded="full"
                      w={10}
                      h={10}
                      p={2}
                      color="white"
                      mt={1}
                    >
                      <Icon as={FaExclamationCircle} w={6} h={6} />{' '}
                    </Box>
                    <Box>
                      <Text fontWeight="semibold" fontSize="lg">
                        Audio and Video Out of Sync
                      </Text>
                      <Text
                        fontSize="sm"
                        color={useColorModeValue('gray.500', 'gray.400')}
                      >
                        If you notice audio and video are out of sync, try
                        regenerating the ad or adjusting the timing in the
                        editor.
                      </Text>
                    </Box>
                  </HStack>
                  {/* Issue 3 */}
                  <HStack align="start" gap={8}>
                    <Box
                      bg={useColorModeValue('gunmetal.400', 'gunmetal.500')}
                      rounded="full"
                      w={10}
                      h={10}
                      p={2}
                      color="white"
                      mt={1}
                    >
                      <Icon as={FaExclamationCircle} w={6} h={6} />{' '}
                    </Box>
                    <Box>
                      <Text fontWeight="semibold" fontSize="lg">
                        Export Fails
                      </Text>
                      <Text
                        fontSize="sm"
                        color={useColorModeValue('gray.500', 'gray.400')}
                      >
                        If your export fails, check your internet connection and
                        try again. If the issue persists, try exporting in a
                        different format.
                      </Text>
                    </Box>
                  </HStack>
                  {/* Issue 4 */}
                  <HStack align="start" gap={8}>
                    <Box
                      bg={useColorModeValue('gunmetal.400', 'gunmetal.500')}
                      rounded="full"
                      w={10}
                      h={10}
                      p={2}
                      color="white"
                      mt={1}
                    >
                      <Icon as={FaExclamationCircle} w={6} h={6} />{' '}
                    </Box>
                    <Box>
                      <Text fontWeight="semibold" fontSize="lg">
                        AI can generate irrelevant content
                      </Text>
                      <Text
                        fontSize="sm"
                        color={useColorModeValue('gray.500', 'gray.400')}
                      >
                        If the AI generates irrelevant content, try being more
                        specific in your project parameters and industry
                        selection.
                      </Text>
                    </Box>
                  </HStack>
                </VStack>
              </VStack>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Box mt={8} textAlign="center">
        <Text color={useColorModeValue('gray.500', 'gray.400')}>
          Need more help? Contact our support team.
        </Text>
        <ChakraLink
          as={RouterLink}
          to="mailto:support@epicly.ai"
          color={useColorModeValue('gunmetal.400', 'gunmetal.300')}
          fontWeight="semibold"
          _hover={{ textDecoration: 'underline' }}
          mt={2}
          display="inline-flex"
          alignItems="center"
        >
          Contact Support
          <Icon as={FaChevronRight} ml={1} w={4} h={4} />
        </ChakraLink>
      </Box>
    </Box>
  );
};

export default Help;
