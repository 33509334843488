import { useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import { useUserContext } from '../providers/UserProvider';
import { deleteScript as deleteScriptService } from '../services/script.service';

const useDeleteScript = () => {
  const { token } = useUserContext();
  const toast = useToast();

  const deleteScript = useCallback(
    async scriptId => {
      try {
          const response = await deleteScriptService(scriptId, token);

          toast({
            title: 'Script deleted.',
            description: `The Script has been deleted.`,
            status: 'success',
            duration: 9000,
            isClosable: true,
          });

          return response;
      } catch (error) {
        toast({
          title: 'Error deleting Script.',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        return null;
      }
    },
    [token, toast]
  );

  return deleteScript;
};

export default useDeleteScript;
