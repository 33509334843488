import React, { useState, useEffect } from 'react';
import {
  CircularProgress,
  CircularProgressLabel,
  Box,
  IconButton,
  keyframes,
  Icon,
} from '@chakra-ui/react';
import { CloseIcon, CheckIcon } from '@chakra-ui/icons';

const sizeMap = {
  sm: '44px',
  md: '60px',
  lg: '80px',
  xl: '100px',
};

const iconSizeMap = {
  sm: '20px',
  md: '24px',
  lg: '32px',
  xl: '40px',
};

// Define the keyframes for the slide-in animation with cubic-bezier easing
const slideInFromBottom = keyframes`
  0% {
    transform: translate(0%, 100%);
  }
  60% {
    transform: translate(0%, -10%);
  }
  100% {
    transform: translate(0%, 0%);
  }
`;

// Define the keyframes for the slide-out animation with cubic-bezier easing
const slideOutToBottom = keyframes`
  0% {
    transform: translate(0%, 0%);
  }
  40% {
    transform: translate(0%, -10%);
  }
  100% {
    transform: translate(0%, 100%);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const UploadCircularProgress = ({
  onCancel,
  progress,
  size = 'md',
  completed,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [animation, setAnimation] = useState('');
  const [iconAnimation, setIconAnimation] = useState('');
  const [showProgressLabel, setShowProgressLabel] = useState(true);

  const handleMouseEnter = () => {
    if (!completed) {
      setIconAnimation(slideInFromBottom);
      setIsHovered(true);
      setShowProgressLabel(false);
    }
  };

  const handleMouseLeave = () => {
    if (!completed) {
      setIconAnimation(slideOutToBottom);
      setTimeout(() => {
        setAnimation(fadeOut);
        setShowProgressLabel(true);
      }, 300); // Delay to match the icon animation duration
      setTimeout(() => {
        setIsHovered(false);
        setAnimation('');
      }, 600); // Delay to match the background fade-out duration
    }
  };

  return (
    <Box
      position="relative"
      display="inline-block"
      textAlign="center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CircularProgress
        value={progress}
        size={sizeMap[size]}
        color="moonstone.400"
      >
        {showProgressLabel && !completed && (
          <CircularProgressLabel>{`${Math.round(progress)}%`}</CircularProgressLabel>
        )}
        {completed && (
          <Icon
            as={CheckIcon} 
            color="green.500" 
            boxSize={iconSizeMap[size]} 
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          />
        )}
      </CircularProgress>
      {isHovered && !completed && (
        <IconButton
          width={sizeMap[size]}
          height={sizeMap[size]}
          position="absolute"
          top="50%"
          left="50%"
          borderRadius="full"
          transform="translate(-50%, -50%)"
          onClick={onCancel}
          aria-label="Cancel Upload"
          overflow="hidden"
          animation={animation ? `${animation} 0.3s ease-out` : ''}
        >
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            display="flex"
            alignItems="center"
            justifyContent="center"
            animation={`${iconAnimation} 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.0) forwards`}
          >
            <CloseIcon boxSize={iconSizeMap[size]} />
          </Box>
        </IconButton>
      )}
    </Box>
  );
};

export default UploadCircularProgress;
