import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, useColorModeValue } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
    field: {
      color: useColorModeValue('black', 'white'), 
      bg: useColorModeValue('white', 'gunmetal.500'), 
      borderColor: useColorModeValue('gray.200', 'gunmetal.400'), 
      cursor: 'pointer',
      _hover: {
        borderColor: useColorModeValue('gray.300', 'gunmetal.500'), 
      },
      _focus: {
        borderColor: 'blue.500', 
        boxShadow: '0 0 0 1px blue.500', 
      },
      _disabled: {
        opacity: 0.4, 
        cursor: 'not-allowed', 
      },
    },
  }));

const gunmetalVariant = definePartsStyle((props) => ({
    field: {
      color: useColorModeValue('black', 'white'), 
      bg: useColorModeValue('white', 'gunmetal.600'), 
      borderColor: useColorModeValue('gray.200', 'gunmetal.500'), 
      cursor: 'pointer',
      _hover: {
        borderColor: useColorModeValue('gray.300', 'gunmetal.700'), 
      },
      _focus: {
        borderColor: useColorModeValue('blue.500', 'gunmetal.500'), 
        boxShadow: useColorModeValue('0 0 0 1px blue.500', '0 0 0 1px gunmetal.500'), 
      },
      _disabled: {
        opacity: 0.4, 
        cursor: 'not-allowed', 
      },
    },
  }));

const beigeVariant = definePartsStyle((props) => ({
    field: {
      color: useColorModeValue('black', 'white'), 
      bg: useColorModeValue('beige.500', 'gunmetal.500'), 
      borderColor: useColorModeValue('beige.400', 'gunmetal.400'), 
      cursor: 'pointer',
      _hover: {
        borderColor: useColorModeValue('beige.500', 'gunmetal.500'), 
      },
      _focus: {
        borderColor: 'blue.500', 
        boxShadow: '0 0 0 1px blue.500', 
      },
      _disabled: {
        opacity: 0.4, 
        cursor: 'not-allowed', 
      },
    },
  }));

export const selectTheme = defineMultiStyleConfig({ 
  baseStyle,
  variants: {
    gunmetal: gunmetalVariant,
    beige: beigeVariant,
  },
});
