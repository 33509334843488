import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Flex,
  HStack,
  Text,
  VStack,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  Box,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useColorModeValue,
  useToast,
  Icon,
  Divider,
} from '@chakra-ui/react';
import FlyoutMenu from '../FlyoutMenu/FlyoutMenu';
import ShotBlock from './ShotBlock';
import MusicSelection from '../MusicSelection/MusicSelection';
import useManageMusic from '../../hooks/useManageMusic';
import useGetScriptById from '../../hooks/useGetScriptById';
import useSaveScript from '../../hooks/useSaveScript';
import { useNavigate } from 'react-router-dom';
import useGenerateVoiceover from '../../hooks/useGenerateVoiceover';
import useManageVoiceover from '../../hooks/useManageVoiceover';
import { FPS, SPEED } from '../../config/config';
import { FaFileExport } from 'react-icons/fa6';
import { totalDuration } from '../../utils/app.utils';
import ScriptEditorHeader from './ScriptEditorHeader';
import VoiceoverControls from './VoiceoverControls';
import MusicControls from './MusicControls';
import useShots from '../../hooks/useShots';
import useManageServices from '../../hooks/useManageServices';

const ScriptEditor = () => {
  const bgColor = useColorModeValue('gray.50', 'gunmetal.700');
  const borderColor = useColorModeValue('gray.200', 'gunmetal.550');
  const textColor = useColorModeValue('gray.800', 'white');
  const subTextColor = useColorModeValue('gray.600', 'gray.400');
  const hoverBgColor = useColorModeValue('gray.100', 'gunmetal.500');
  const buttonBgColor = useColorModeValue('beige.300', 'beige.300');
  const buttonHoverBgColor = useColorModeValue('beige.400', 'beige.400');
  const errorColor = useColorModeValue('red.500', 'red.300');

  const toast = useToast();
  const { services, loading } = useManageServices();
  const { scriptId } = useParams();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isFlyoutOpen,
    onOpen: onFlyoutOpen,
    onClose: onFlyoutClose,
  } = useDisclosure();

  const { script, setScript, scriptLoading, error } = useGetScriptById(scriptId);
  const { updateScriptData, saveLoading } = useSaveScript();
  const { generateVoiceover, voiceoverLoading } = useGenerateVoiceover();
  const { addAudioToProject, removeAudioFromProject } = useManageVoiceover();
  const { addSongToProject, removeSongFromProject } = useManageMusic();

  const [voiceoverUrl, setVoiceoverUrl] = useState(
    script?.voiceover?.audioPath || null
  );
  const [musicUrl, setMusicUrl] = useState(script?.music?.url || null);

  const { shots, timeOverLimit, addShot, removeShot, updateShotDialogue } =
    useShots(script?.script, script, FPS, SPEED);

  useEffect(() => {
    console.log('services', services)
    console.log('script', script)
  }, [services, script])

  useEffect(() => {
    if (script?.voiceover?.audioPath) {
      setVoiceoverUrl(script.voiceover.audioPath);
    } else {
      setVoiceoverUrl(null);
    }

    if (script?.music?.url) {
      setMusicUrl(script.music.url);
    }
  }, [script]);

  const handleAddShot = () => {
    addShot(updatedShots => {
      handleSave(updatedShots);
    });
  };

  const handleRemoveShot = shotId => {
    removeShot(shotId, updatedShots => {
      handleSave(updatedShots);
    });
  };

  const handleDialogueSubmit = (value, shotId) => {
    updateShotDialogue(value, shotId, updatedShots => {
      handleSave(updatedShots);
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSave = async (shotsToSave = shots) => {
    console.log('handleSave shotsToSave:', shotsToSave);

    try {
      const updatedScriptData = {
        _id: scriptId,
        script: shotsToSave,
      };

      const savedScript = await updateScriptData(scriptId, updatedScriptData);

      // Update the script state while preserving existing properties
      setScript(prevScript => ({
        ...prevScript,
        script: savedScript.updatedScript.script, // Update only the 'script' field
      }));

      console.log('Updated Script:', savedScript);
    } catch (error) {
      console.error('Error saving shots:', error);
    }
  };

  const handleGenerateVoiceover = async () => {
    try {
      const newVoiceoverUrl = await generateVoiceover(script);

      if (newVoiceoverUrl) {
        setVoiceoverUrl(newVoiceoverUrl);

        const audioData = {
          title: script.title,
          audioPath: newVoiceoverUrl,
        };

        // Add the audio to the project
        const addedVoiceover = await addAudioToProject({
          projectId: script.project,
          scriptId,
          audioData,
        });

        // Log addedVoiceover to inspect its structure
        console.log('Added Voiceover:', addedVoiceover);

        // Update the script state with the new voiceover data
        const updatedScript = {
          ...script,
          voiceover: {
            ...audioData,
            _id: addedVoiceover.script.voiceover._id, // Adjust based on the actual property name
          },
        };

        setScript(updatedScript);

        console.log('Updated Script:', updatedScript);
      }
    } catch (error) {
      console.error('Error generating voiceover:', error);
    }
  };

  const handleRemoveVoiceover = async () => {
    try {
      console.log('handleRemoveVoiceover', script);
      setVoiceoverUrl(null);

      await removeAudioFromProject({
        projectId: script.project,
        scriptId,
        audioId: script.voiceover._id,
      });

      // Update the script state to remove the voiceover
      const updatedScript = {
        ...script,
        voiceover: null,
      };

      setScript(updatedScript);

      toast({
        title: 'Voiceover removed',
        description: 'The voiceover has been removed successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error removing voiceover:', error);
      toast({
        title: 'Error',
        description: 'Failed to remove voiceover. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRegenerateVoiceover = async () => {
    try {

      console.log('Removing Voiceover from DB');

      // Remove the current voiceover from the DB
      await removeAudioFromProject({
        projectId: script.project,
        scriptId,
        audioId: script.voiceover._id,
      });

      console.log('Generating new Voiceover');

      // Generate a new voiceover
      const newVoiceoverUrl = await generateVoiceover(script);

      if (newVoiceoverUrl) {
        console.log('Setting new Voiceover URL');
        setVoiceoverUrl(newVoiceoverUrl);

        console.log('Adding new Voiceover to DB');

        const audioData = {
          title: script.title,
          audioPath: newVoiceoverUrl,
        };

        // Add the new voiceover to the project
        const addedVoiceover = await addAudioToProject({
          projectId: script.project,
          scriptId,
          audioData,
        });

        console.log('Updating Script State with: ', addedVoiceover.script.voiceover);

        // Update the script state with the new voiceover data
        const updatedScript = {
          ...script,
          voiceover: {
            ...audioData,
            _id: addedVoiceover.script.voiceover._id,
          },
        };

        setScript(updatedScript);

        toast({
          title: 'Voiceover regenerated',
          description: 'The voiceover has been regenerated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error regenerating voiceover:', error);
      toast({
        title: 'Error',
        description: 'Failed to regenerate voiceover. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEditMusic = () => {
    console.log('Edit Music');
  };

  const handleEditVoiceover = () => {
    console.log('Edit Voiceover');
  };

  const handleRemoveMusic = async () => {
    try {
      await removeSongFromProject({
        projectId: script.project,
        scriptId,
        musicId: script.music._id,
      });
      setMusicUrl(null);
    } catch (error) {
      console.error('Error removing music:', error);
      toast({
        title: 'Error',
        description: 'Failed to remove music. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEditDuration = () => {
    console.log('Edit Duration');
  };

  const handleExport = () => {
    console.log('Export');
  };

  const handleMusicModalOpen = () => {
    console.log('Select Music');
    onOpen();
  };

  const handleMusicSelect = async selectedMusic => {
    try {
      const musicData = {
        title: selectedMusic.title,
        url: selectedMusic.url,
      };

      // const addedMusic = await addSongToProject({ projectId: script.project, musicId: selectedMusic._id, musicData });
      // console.log('Added Music:', addedMusic);

      setMusicUrl(selectedMusic.url);

      // onClose();
    } catch (error) {
      console.error('Error adding music:', error);
      toast({
        title: 'Error',
        description: 'Failed to add music. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const getLoadingText = () => {
    if (saveLoading) return 'Saving...';
    if (voiceoverLoading) return 'Processing Voiceover...';
    // if (musicLoading) return "Processing Music...";
    return 'Loading...';
  };

  if (scriptLoading) {
    return (
      <Flex justify="center" align="center" h="100vh" bg={bgColor}>
        <Spinner color={textColor} />
        <Text ml={3} color={subTextColor}>
          Loading...
        </Text>
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex justify="center" align="center" h="100vh" bg={bgColor}>
        <Text color={errorColor}>Error: {error.message}</Text>
      </Flex>
    );
  }

  return (
    <>
      <Flex
        direction="column"
        p={4}
        borderRadius={'lg'}
        bg={bgColor}
        boxShadow="xl"
        border={'1px solid'}
        borderColor={borderColor}
        mr={20}
      >
        {/* Header */}
        <ScriptEditorHeader
          scriptTitle={script?.title}
          targetDuration={script?.duration}
          currentDuration={totalDuration(shots, FPS, SPEED)}
          timeOverLimit={timeOverLimit}
          onBack={handleBack}
          onSave={() => handleSave()}
          onEditDuration={handleEditDuration}
          saveLoading={saveLoading}
          voiceoverLoading={voiceoverLoading}
        />

        {/* Content */}
        <HStack align={'stretch'} justify={'stretch'} gap={8}>
          <VStack
            flex="2"
            spacing={4}
            mt={4}
            align="stretch"
            justify={'stretch'}
          >
            {shots
              .filter(shot => shot.audio.some(item => item.type === 'DIALOGUE'))
              .map(shot => (
                <ShotBlock
                  key={shot._id}
                  shot={shot}
                  onDialogueSubmit={handleDialogueSubmit}
                  onRemoveShot={handleRemoveShot}
                  FPS={FPS}
                  SPEED={SPEED}
                />
              ))}

            <Flex
              bg={'transparent'}
              flexDirection={'column'}
              p={4}
              align={'center'}
              justify={'center'}
              borderRadius="md"
              borderColor={timeOverLimit ? 'gray.400' : borderColor}
              borderWidth="1px"
              borderStyle={'dashed'}
              transition={'all 0.3s'}
              onClick={handleAddShot}
              _hover={{
                bg: hoverBgColor,
                cursor: 'pointer',
                borderColor: borderColor,
                borderWidth: '1px',
                borderStyle: 'solid',
              }}
              opacity={1}
            >
              <Text>Add Script Block</Text>
            </Flex>
          </VStack>

          <VStack
            mt={0}
            flex={'1'}
            spacing={4}
            align={'stretch'}
            justify={'stretch'}
          >
            <VoiceoverControls
              voiceoverUrl={voiceoverUrl}
              onRemoveVoiceover={handleRemoveVoiceover}
              onRegenerateVoiceover={handleRegenerateVoiceover}
              onGenerateVoiceover={handleGenerateVoiceover}
              voiceoverLoading={voiceoverLoading}
              timeOverLimit={timeOverLimit}
              handleEditVoiceover={handleEditVoiceover}
              duration={script?.duration}
            />

            <Divider />

            <MusicControls
              musicUrl={musicUrl}
              onRemoveMusic={handleRemoveMusic}
              onOpenMusicModal={handleMusicModalOpen}
              timeOverLimit={timeOverLimit}
            />

            <Button
              bg={buttonBgColor}
              _hover={{ bg: buttonHoverBgColor }}
              onClick={handleExport}
              leftIcon={<Icon as={FaFileExport} />}
              isDisabled={!musicUrl || !voiceoverUrl}
              isLoading={saveLoading || voiceoverLoading}
              loadingText={getLoadingText()}
              mt={8}
              py={10}
            >
              Export
            </Button>
          </VStack>
        </HStack>

        {/* Modal for Music Selection */}
        <Modal isOpen={isOpen} onClose={onClose} size="full">
          <ModalOverlay />
          <ModalContent bg={bgColor}>
            <ModalCloseButton />
            <ModalBody>
              <MusicSelection
                onMusicSelect={handleMusicSelect}
                onMusicRemove={handleRemoveMusic}
                selectedMusicUrl={musicUrl}
              />
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>

      <Box
        position="fixed"
        bottom="50%"
        right="-50px"
        transform={'rotate(-90deg)'}
      >
        <Button
          bg={'beige.300'}
          _hover={{ bg: 'beige.400' }}
          onClick={onFlyoutOpen}
        >
          Project Settings
        </Button>
      </Box>

      <FlyoutMenu isOpen={isFlyoutOpen} onClose={onFlyoutClose} />
    </>
  );
};

export default ScriptEditor;
