import React, { useState, useEffect, useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Input,
  Box,
  useToast,
  HStack,
  Icon,
  Divider,
  useColorModeValue,
} from '@chakra-ui/react';
import { BsShieldFillExclamation } from 'react-icons/bs';

const DeleteItemModal = ({ isOpen, onClose, itemName, itemType, onDelete }) => {
  const [inputValue, setInputValue] = useState('');
  const toast = useToast();

  // Color mode values
  const bgColor = useColorModeValue('beige.100', '#232C33');
  const headerBgColor = useColorModeValue('beige.200', '#314048');
  const borderColor = useColorModeValue('gray.300', '#3E4E57');
  const textColor = useColorModeValue('gray.800', 'white');
  const warningBgColor = useColorModeValue('orange.100', '#1C242A');
  const warningIconColor = useColorModeValue('orange.500', '#FFB223');
  const inputBgColor = useColorModeValue('white', '#1C242A');
  const inputBorderColor = useColorModeValue('gray.300', '#3E4E57');
  const cancelButtonBgColor = useColorModeValue('gray.200', 'transparent');
  const cancelButtonHoverBgColor = useColorModeValue('gray.300', '#3E4E57');
  const deleteButtonBgColor = useColorModeValue('red.500', 'red.500');
  const deleteButtonHoverBgColor = useColorModeValue('red.600', 'red.600');

  const handleDelete = useCallback(() => {
    if (inputValue === itemName) {
      onDelete();
      onClose();
    } else {
      toast({
        title: 'Name Mismatch',
        description: `Please type the exact name of the ${itemType} to confirm deletion.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [inputValue, itemName, itemType, onDelete, onClose, toast]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && isOpen) {
        handleDelete();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, handleDelete]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg={bgColor}
        color={textColor}
        borderRadius="md"
        borderWidth="1px"
        borderColor={borderColor}
      >
        <ModalHeader 
          bg={headerBgColor}
          color={textColor}
          fontSize={'lg'}
          fontWeight={'normal'}
          borderBottom="1px solid"
          borderColor={borderColor}
        >
          Confirm deletion of {itemName}
        </ModalHeader>
        <ModalBody>
          <HStack
            spacing={4}
            bg={warningBgColor}
            py={4}
            px={8}
            borderRadius="md"
            boxShadow="sm"
            border={'1px solid'}
            borderColor={borderColor}
            my={8}
          >
            <Icon as={BsShieldFillExclamation} color={warningIconColor} boxSize={6} />
            <Text>
              This action cannot be undone.
            </Text>
          </HStack>
          
          <Box mb={8}>
            <Text mb={8}>
              This will permanently delete the {itemType} and all of its data.
            </Text>

            <Divider />

            <Text mt={8} mb={4}>Type <b>{itemName}</b> to confirm.</Text>
            <Input
              placeholder={`Type the ${itemType} name here`}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              bg={inputBgColor}
              borderColor={inputBorderColor}
              _hover={{ borderColor: inputBorderColor }}
              _focus={{ borderColor: inputBorderColor, boxShadow: 'none' }}
            />
          </Box>
          <Divider />

        </ModalBody>
        <ModalFooter>
          <Button 
            onClick={onClose} 
            mr={3} 
            variant={'ghost'} 
            color={textColor}
            bg={cancelButtonBgColor}
            borderRadius='md'
            _hover={{ 
              bg: cancelButtonHoverBgColor, 
            }}
          >
            Cancel
          </Button>
          <Button
            bg={deleteButtonBgColor}
            color={'white'}
            borderRadius='md' 
            onClick={handleDelete}
            isDisabled={inputValue !== itemName}
            _hover={{
              bg: deleteButtonHoverBgColor,
            }}
          >
            I understand, delete this {itemType}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteItemModal;
