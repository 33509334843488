import { useState, useEffect } from 'react';
import {
  totalDuration,
  parseTargetDuration,
  smpteToSeconds,
} from '../utils/app.utils';

const useShots = (initialShots, script, FPS, SPEED) => {
  const [shots, setShots] = useState(initialShots || []);
  const [timeOverLimit, setTimeOverLimit] = useState(false);

  useEffect(() => {
    setShots(initialShots || []);
  }, [initialShots]);

  useEffect(() => {
    if (script) {
      const scriptDuration = totalDuration(shots, FPS, SPEED);
      const targetDurationSeconds = parseTargetDuration(script.duration);
      const currentDurationSeconds = smpteToSeconds(scriptDuration, FPS);

      if (currentDurationSeconds > targetDurationSeconds) {
        setTimeOverLimit(true);
      } else {
        setTimeOverLimit(false);
      }
    }
  }, [shots, script, FPS, SPEED]);

  const addShot = callback => {
    const newShot = {
      _id: Date.now().toString(),
      audio: [
        {
          type: 'DIALOGUE',
          content: '',
          phoneme: { alphabet: null, ph: null },
        },
      ],
      visual: [],
    };

    setShots(prevShots => {
      const updatedShots = [...prevShots, newShot];
      if (callback) {
        callback(updatedShots);
      }
      return updatedShots;
    });
  };

  const removeShot = (shotId, callback) => {
    setShots(prevShots => {
      const updatedShots = prevShots.filter(shot => shot._id !== shotId);
      if (callback) {
        callback(updatedShots);
      }
      return updatedShots;
    });
  };

  const updateShotDialogue = (value, shotId, callback) => {
    setShots(prevShots => {
      const updatedShots = prevShots.map(shot => {
        if (shot._id === shotId) {
          const updatedAudio = shot.audio.map(item => {
            if (item.type === 'DIALOGUE') {
              return { ...item, content: value };
            }
            return item;
          });
          return { ...shot, audio: updatedAudio };
        }
        return shot;
      });

      if (callback) {
        callback(updatedShots);
      }

      return updatedShots;
    });
  };

  return {
    shots,
    timeOverLimit,
    addShot,
    removeShot,
    updateShotDialogue,
  };
};

export default useShots;
