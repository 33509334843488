import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import theme from './theme';
import { PROVIDER_CONFIG } from './config/config';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { UserProvider } from './providers/UserProvider';
import { AdGenerationProvider } from './providers/AdGenerationProvider';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || '/');
  };

  return (
    <Auth0Provider 
      onRedirectCallback={onRedirectCallback}
      cacheLocation='localstorage'
      useRefreshTokens={true}
      {...props}
    >
      {children}
    </Auth0Provider>
  )
};

root.render(
  <StrictMode>
    <Router>
      <Auth0ProviderWithRedirectCallback {...PROVIDER_CONFIG}>
        <ChakraProvider theme={theme}>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <UserProvider>
            <AdGenerationProvider>
              <App />
            </AdGenerationProvider>
          </UserProvider>
        </ChakraProvider>
      </Auth0ProviderWithRedirectCallback>
    </Router>
  </StrictMode>
);
