// src/services/billing.service.js

import api from '../utils/api.utils';
import { handleResponse } from '../utils/app.utils';

// Create a checkout session
export const createCheckoutSession = async (data, token) => {
  try {
    const response = await api.post(
      '/billing/create-checkout-session',
      data,
      {
        customHeaders: { token },
      }
    );

    return handleResponse(response, 'Successfully created checkout session.');
  } catch (error) {
    console.error('Error creating checkout session:', error.message);
    throw new Error('Failed to create checkout session.');
  }
};

export const retrieveCheckoutSession = async (sessionId, token) => {
  try {
    const response = await api.post('/billing/retrieve-checkout-session', { sessionId }, { customHeaders: { token } });
    return handleResponse(response, 'Successfully retrieved checkout session.');
  } catch (error) {
    console.error('Error retrieving checkout session:', error.message);
    throw new Error('Failed to retrieve checkout session.');
  }
};

// Get the user's billing portal session
export const getCustomerPortalSession = async (token) => {
  try {
    const response = await api.post(
      '/billing/customer-portal',
      {},
      {
        customHeaders: { token },
      }
    );

    return handleResponse(response, 'Successfully retrieved billing portal session.');
  } catch (error) {
    console.error('Error getting the billing portal session:', error.message);
    throw new Error('Failed to get the billing portal session.');
  }
};

// Process the Stripe session success
export const processStripeSuccess = async (sessionId, token) => {
  try {
    // Get the details of the Stripe session
    const response = await api.post(
      '/billing/success',
      {
        sessionId,
      },
      {
        customHeaders: { token },
      }
    );

    return handleResponse(response, 'Successfully processed Stripe session.');
  } catch (error) {
    console.error('Error processing Stripe session:', error.message);
    throw new Error('Failed to process Stripe session.');
  }
};
