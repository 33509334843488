import { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useUserContext } from '../providers/UserProvider';
import { addSongToProject as addSongToProjectService } from '../services/music.service';
import { removeSongFromProject as removeSongFromProjectService } from '../services/music.service';

const useManageMusic = () => {
  const { token } = useUserContext();
  const toast = useToast();

  const [manageMusicLoading, setManageMusicLoading] = useState(false);

  const addSongToProject = async ({ projectId, musicId, token }) => {
    setManageMusicLoading(true);
    
    try {
      const response = await addSongToProjectService({ projectId, musicId, token });
      return response;
    } catch (error) {
      console.error('Error adding audio to project:', error);
      toast({
        title: 'Error',
        description: 'Error adding audio to project',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      throw error;
    } finally {
      setManageMusicLoading(false);
    }
  };

  const removeSongFromProject = async ({ projectId, musicId, deleteSong }) => {
    setManageMusicLoading(true);

    try {
      const response = await removeSongFromProjectService({ projectId, musicId, deleteSong, token });
      return response;
    } catch (error) {
      console.error('Error removing audio from project:', error);
      throw error;
    } finally {
      setManageMusicLoading(false);
    }
  };

  return { addSongToProject, removeSongFromProject, manageMusicLoading };
};

export default useManageMusic;
