import React from 'react';
import {
  Button,
  Text,
  Heading,
  useBreakpointValue,
  Flex,
  Badge,
  useColorModeValue,
} from '@chakra-ui/react';
import useGetPlans from '../../hooks/useGetPlans';

const SubscriptionPlan = ({ user, subscription }) => {
  const { plans, loading, isAnnual, getPlanPrice, toggleInterval } =
    useGetPlans();
  const columnCount = useBreakpointValue({ base: 1, md: 3 });
  const spacingValue = useBreakpointValue({ base: 4, md: 10 });
  const bg = useColorModeValue('white', 'gunmetal.700');
  const borderColor = useColorModeValue('gunmetal.400', 'gunmetal.500');
  const handleSubscriptionChange = planId => {
    console.log('planId:', planId);
  };

  // Need to create customer portal session
  const manageSubscriptionUrl = 'https://www.pizzapay.com/settings';

  if (loading) {
    return <Text>Loading plans...</Text>;
  }

  return (
    <Flex
      bg={bg}
      p={4}
      rounded="lg"
      shadow="lg"
      border="1px solid"
      borderColor={borderColor}
      direction="column"
      align="flex-start"
      justify="center"
    >
      <Heading as="h2" size="md" mb={2}>
        Your current subscription:
      </Heading>
      <Badge mb={10} colorScheme="green" fontSize="lg" p={2}>
        {subscription.subscriptionPlan.name
          ? subscription.subscriptionPlan.name
          : 'None'}
      </Badge>
      <Button as="a" href={manageSubscriptionUrl} target="_blank">
        Manage Subscription
      </Button>
    </Flex>
  );
};

export default SubscriptionPlan;
