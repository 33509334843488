import React, { useState, useEffect } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  MenuDivider,
  Spinner,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const CustomSelect = ({
  options,
  placeholder,
  value,
  onChange,
  onSelectNewProject,
  isLoading,
}) => {
  const [selectedOption, setSelectedOption] = useState(value || { value: '', label: '' });

  useEffect(() => {
    setSelectedOption(value || { value: '', label: '' });
  }, [value]);

  const handleSelect = option => {
    if (option.value === 'new-project') {
      onSelectNewProject();
    } else {
      onChange(option);
      setSelectedOption(option);
    }
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        bg="gunmetal.500"
        color="white"
      >
        {isLoading ? (
          <Spinner size="sm" />
        ) : selectedOption.label ? (
          selectedOption.label
        ) : (
          placeholder
        )}
      </MenuButton>
      <MenuList
        bg="gunmetal.500"
        borderColor="gunmetal.300"
        borderWidth={'1px'}
        boxShadow={'xl'}
        maxHeight="200px" // Limit height for overflow
        overflowY="auto" // Enable vertical scrolling
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'gray.600',
            borderRadius: '8px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: 'gray.700',
          },
        }}
      >
        {options.map((option, index) => (
          <React.Fragment key={`${option.value}-${index}`}>
            <MenuItem
              onClick={() => handleSelect(option)}
              bg="gunmetal.500"
              color="white"
              _hover={{ bg: 'gunmetal.400' }}
              _focus={{ bg: 'gunmetal.400' }}
            >
              {option.label || 'Unnamed Project'} {/* Ensure label is displayed */}
            </MenuItem>
            <MenuDivider m={1} />
          </React.Fragment>
        ))}
      </MenuList>
    </Menu>
  );
};

export default CustomSelect;
