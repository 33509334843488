import { useState, useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import api from '../utils/api.utils';
import { useUserContext } from '../providers/UserProvider';

const useUpload = (projectId, updateProjectData) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const toast = useToast();
  const { token } = useUserContext(); 
 
  const handleUpload = useCallback(
    async (file) => {
      setLoading(true);
      setProgress(0);

      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name);
        formData.append('fileType', file.type);

        const response = await api.post('/upload/upload-media', formData, {
          customHeaders: {
            contentType: 'multipart/form-data',
            token,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        });

        if (response.status === 200 && response.data.url) {
          const uploadedImageUrl = response.data.url;

          setLoading(false);
          setProgress(100);

          // Step 5: Display success toast
          toast({
            title: 'Upload Successful',
            description: 'Your image has been uploaded successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });

          return uploadedImageUrl;
        } else {
          throw new Error('Upload failed');
        }
      } catch (error) {
        console.error('File upload error:', error);
        // Step 6: Display error toast
        toast({
          title: 'Upload Failed',
          description:
            error.response?.data?.message ||
            error.message ||
            'An error occurred during the upload.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
      }
    },
    [projectId, updateProjectData, token, toast]
  );

  return { handleUpload, loading, progress };
};

export default useUpload;
