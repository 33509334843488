import { useState, useEffect, useCallback } from 'react'
import api from '../utils/api.utils'
import { handleResponse } from '../utils/app.utils'
import { useUserContext } from '../providers/UserProvider';

const useManageServices = () => {
    const { token } = useUserContext()
    const [services, setServices] = useState([])
    const [loading, setLoading] = useState(false)

// Fetch Specific Service Cost
const fetchServiceCost = async (type, duration) => {
    try {
        const response = await api.get(`/credits/services/cost?type=${type}&duration=${duration}`, {
            customHeaders: { token }
        })
        handleResponse(response)
        return response.data
    } catch (error) {
        console.error('Error fetching service cost:', error);
        throw error;
    }
}

// Fetch all services
const fetchAllServices = useCallback(async () => {
    try {
        setLoading(true)
        const response = await api.get('/credits/services', {
            customHeaders: { token }
        })

        const data = handleResponse(response, 'Error fetching services')
        setServices(data)
    } catch (error) {
        console.error('Error fetching services:', error);
        throw error;
    } finally {
        setLoading(false)
    }

}, [token])

useEffect(() => {
        fetchAllServices()
    }, [fetchAllServices])

    return { fetchAllServices, fetchServiceCost, services, loading }

}

export default useManageServices;
