// src/services/ai.service.js

import api from '../utils/api.utils';
import { handleResponse } from '../utils/app.utils';

export const generateScript = async (data, token) => {
  const {
    adType,
    project,
    brandName,
    productName,
    callToAction,
    duration,
    industry,
    targetAudience,
    scriptIdea,
  } = data;
  try {
    const response = await api.post('/ai', {
      adType,
      project,
      brandName,
      productName,
      callToAction,
      duration,
      industry,
      targetAudience,
      scriptIdea,
    }, {
      customHeaders: { token },
    });
    return handleResponse(response, 'Error generating script');
  } catch (error) {
    console.error('Error generating script:', error.message);
    throw new Error('Failed to generate script');
  }
};

export const generateScriptBlock = async ({
  context,
  position,
  adType,
  token,
}) => {

  try {
    const response = await api.post('/ai/block', { context, position, adType }, {
      customHeaders: { token },
    });
    return handleResponse(response, 'Error generating script block');
  } catch (error) {
    console.error('Error generating script block:', error.message);
    throw new Error('Failed to generate script block');
  }
};

export const getScriptTopicSuggestions = async (data, token) => {
  console.log('data', data);
  try {
    const response = await api.post('/ai/script-topic-suggestions', data, {
      customHeaders: { token },
    });
    return handleResponse(response, 'Error fetching script topic suggestions');
  } catch (error) {
    console.error('Error fetching script topic suggestions:', error.message);
    throw error;
  }
};

export const identifyPhonemes = async (text, token) => {
  try {
    const response = await api.post('/ai/identify-phonemes', { text }, {
      customHeaders: { token },
    });
    return handleResponse(response, 'Error identifying phonemes');
  } catch (error) {
    console.error('Error identifying phonemes:', error.message);
    throw new Error('Failed to identify phonemes');
  }
};

export const generateTTS11Labs = async ({ title, script, voice, duration }, token) => {
  try {
    const response = await api.post('/ai/tts11', { title, script, voice, duration }, {
      customHeaders: { token },
    });
    return handleResponse(response, 'Error generating TTS with Eleven Labs');
  } catch (error) {
    console.error('Error generating TTS with Eleven Labs:', error.message);
    throw error;
  }
};

export const generateMusic = async ({title, duration, genre, mood, tone, token}) => {
  try {
    const data = { title, duration, genre, mood, tone };

    const response = await api.post('/ai/generate-music', data, {
      customHeaders: { token },
    });
    return response.data;
  } catch (error) {
    console.error('Error generating music:', error.message);
    throw new Error('Failed to generate music');
  }
};

export const getPredictionStatus = async (predictionId, token) => {
  try {
    const response = await api.get(`/ai/prediction/${predictionId}`, {
      customHeaders: { token },
    });
    return handleResponse(response, 'Error checking prediction status');
  } catch (error) {
    console.error('Error checking prediction status:', error.message);
    throw new Error('Failed to check prediction status');
  }
};