import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Text,
  HStack,
  VStack,
  Select,
  Button,
  Skeleton,
  Icon,
} from '@chakra-ui/react';
import useGetAvailableSongs from '../../hooks/useGetAvailableSongs';
import useGenerateMusic from '../../hooks/useGenerateMusic';
import Pagination from '../Pagination/Pagination';
import { AnimatePresence } from 'framer-motion';
import {
  MotionHStack,
  MotionVStack,
  containerVariants,
  itemVariants,
} from '../../config/config';
import { FaMusic, FaPlus } from 'react-icons/fa';

const MusicSelection = ({ onMusicSelect, onMusicRemove, selectedMusicUrl }) => {
  const { songs, loading: songsLoading } = useGetAvailableSongs();
  const { generateMusic, loading: generateMusicLoading } = useGenerateMusic();
  const [filters, setFilters] = useState({ genre: '', mood: '', tone: '' });
  const [selectedSong, setSelectedSong] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const filteredSongs = useMemo(() => {
    return songs.filter(song => {
      const genreMatch =
        !filters.genre ||
        song.genre.toLowerCase() === filters.genre.toLowerCase();

      const moodMatch =
        !filters.mood ||
        (Array.isArray(song.mood)
          ? song.mood.some(m => m.toLowerCase() === filters.mood.toLowerCase())
          : song.mood.toLowerCase() === filters.mood.toLowerCase());

      const toneMatch =
        !filters.tone || song.tone.toLowerCase() === filters.tone.toLowerCase();

      return genreMatch && moodMatch && toneMatch;
    });
  }, [songs, filters]);

  const paginatedSongs = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredSongs.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredSongs, currentPage]);

  const totalPages = Math.ceil(filteredSongs.length / itemsPerPage);

  const handleFilterChange = e => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value,
    }));
    setCurrentPage(1);
  };

  const handleSelectSong = song => {
    if (selectedMusicUrl === song.url) {
      onMusicRemove();
    } else {
      onMusicSelect(song);
    }
  };

  const handleGenerateMusic = async () => {
    try {
      const newSongUrl = await generateMusic(filters);
      setSelectedSong({ url: newSongUrl, title: 'Generated Song' });
    } catch (error) {
      console.error('Error generating music:', error);
    }
  };

  useEffect(() => {
    console.log('selected song', selectedSong);
  }, [selectedSong]);

  return (
    <Box
      p={8}
      bg="gunmetal.700"
      color="white"
      borderRadius="md"
      maxW="1400px"
      mx="auto"
      w="full"
    >
      <HStack spacing={4} align="center" mb={2}>
        <Text fontSize={{ base: 'lg', md: '2xl' }}>Step 5</Text>
        <Icon as={FaMusic} w={6} h={6} color="gunmetal.300" mx={4} />
        <Text fontSize={{ base: 'lg', md: '2xl' }}>
          Select or Generate Music
        </Text>
      </HStack>
      <Text mb={8}>Choose or generate music for your ad.</Text>

      <HStack spacing={4} mb={8}>
        <Select placeholder="Genre" name="genre" onChange={handleFilterChange}>
          <option value="soft rock">Soft Rock</option>
          <option value="ambient">Ambient</option>
          <option value="funk">Funk</option>
          <option value="hip-hop">Hip Hop</option>
          <option value="reggae">Reggae</option>
          <option value="acoustic">Acoustic</option>
          <option value="punk">Punk</option>
          <option value="salsa">Salsa</option>
          <option value="jazz">Jazz</option>
          <option value="electronica">Electronica</option>
        </Select>
        <Select placeholder="Mood" name="mood" onChange={handleFilterChange}>
          <option value="groovy">Groovy</option>
          <option value="corporate">Corporate</option>
          <option value="youthful">Youthful</option>
          <option value="fun">Fun</option>
          <option value="calm">Calm</option>
          <option value="modern">Modern</option>
          <option value="acoustic">Acoustic</option>
          <option value="motivational">Motivational</option>
          <option value="tranquil">Tranquil</option>
          <option value="uplifting">Uplifting</option>
          <option value="motivational">Motivational</option>
          <option value="urban">Urban</option>
        </Select>
        <Select placeholder="Tone" name="tone" onChange={handleFilterChange}>
          <option value="upbeat">Upbeat</option>
          <option value="professional">Professional</option>
          <option value="acoustic">Acoustic</option>
          <option value="ambient">Ambient</option>
          <option value="corporate">Corporate</option>
        </Select>
      </HStack>

      <MotionVStack
        spacing={4}
        align="stretch"
        mt={8}
        bg={'blackAlpha.200'}
        variants={containerVariants}
        initial="hidden"
        animate="show"
      >
        <Button
          onClick={handleGenerateMusic}
          isLoading={generateMusicLoading}
          mt={4}
          bg="beige.300"
          color="gunmetal.700"
          leftIcon={<FaPlus />}
        >
          Generate Music
        </Button>
        <AnimatePresence>
          {songsLoading
            ? Array.from({ length: itemsPerPage }).map((_, index) => (
                <Skeleton key={index} height="100px" />
              ))
            : paginatedSongs.map((song, index) => (
                <MotionHStack
                  key={song.id || index}
                  custom={index}
                  spacing={4}
                  bg={'gradBlack'}
                  borderRadius={'md'}
                  justify={'space-between'}
                  p={4}
                  _hover={{ bg: 'gradBlackHover' }}
                  variants={itemVariants}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                >
                  <HStack spacing={4} align={'stretch'}>
                    <VStack align="stretch" spacing={0} p={4} w={'200px'}>
                      <Text fontSize={'sm'} color={'beige.300'}>
                        Song Title
                      </Text>
                      <Text fontSize={'lg'}>{song.title}</Text>
                    </VStack>

                    <VStack align="stretch" spacing={0} p={4} w={'150px'}>
                      <Text fontSize={'sm'} color={'beige.300'}>
                        Genre
                      </Text>
                      <Text fontSize={'lg'}>
                        {song.genre.charAt(0).toUpperCase() +
                          song.genre.slice(1)}
                      </Text>
                    </VStack>

                    <VStack align="stretch" spacing={0} p={4} w={'200px'}>
                      <Text fontSize={'sm'} color={'beige.300'}>
                        Mood
                      </Text>
                      <Text fontSize={'lg'}>
                        {Array.isArray(song.mood)
                          ? song.mood
                              .map(
                                mood =>
                                  mood.charAt(0).toUpperCase() + mood.slice(1)
                              )
                              .join(', ')
                          : song.mood.charAt(0).toUpperCase() +
                            song.mood.slice(1)}
                      </Text>
                    </VStack>

                    <VStack align="stretch" spacing={0} p={4} w={'150px'}>
                      <Text fontSize={'sm'} color={'beige.300'}>
                        Tone
                      </Text>
                      <Text fontSize={'lg'}>
                        {song.tone.charAt(0).toUpperCase() + song.tone.slice(1)}
                      </Text>
                    </VStack>
                  </HStack>

                  <audio controls>
                    <source src={song.url} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>

                  <Button
                    variant={ selectedMusicUrl === song.url ? 'solid' : 'outline' }
                    borderRadius={'full'}
                    w={'100px'}
                    py={6}
                    bg={
                      selectedMusicUrl === song.url
                        ? 'beige.300'
                        : 'transparent'
                    }
                    borderColor={
                      selectedMusicUrl === song.url ? 'beige.300' : 'white'
                    }
                    color={'white'}
                    onClick={() => handleSelectSong(song)}
                  >
                    {selectedMusicUrl === song.url ? 'Selected' : 'Select'}
                  </Button>
                </MotionHStack>
              ))}
        </AnimatePresence>
      </MotionVStack>

      {!songsLoading && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      )}
    </Box>
  );
};

export default MusicSelection;
