import React, { useEffect, useState, useRef } from 'react';
import {
  Flex,
  Text,
  IconButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@chakra-ui/react';
import { FaPlay, FaPause, FaVolumeUp } from 'react-icons/fa';

const AudioPlayer = ({
  isPlaying,
  currentTime,
  duration,
  volume,
  frequencyData = [],
  onPlayPause,
  onVolumeChange,
  onSeek,
  fullWaveformData = [], 
}) => {
  const svgRef = useRef();
  const [rectCount, setRectCount] = useState(100);

  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  useEffect(() => {
    if (svgRef.current) {
      const svgWidth = svgRef.current.clientWidth;
      setRectCount(Math.floor(svgWidth / 4)); 
    }
  }, [svgRef.current]);

  return (
    <Flex direction="column" p={4} bg={'gunmetal.500'} w="full" borderRadius="md">
      {/* Visualizer */}
      <Flex h="100px" mb={4} bg="gunmetal.550" alignItems="center" w="full" borderRadius={'md'} shadow={'inner'}>
        <svg ref={svgRef} width="100%" height="100%">
          {/* Render the full waveform in a darker shade */}
          {fullWaveformData.slice(0, rectCount).map((value, index) => (
            <rect
              key={`full-${index}`}
              x={`${(index / rectCount) * 100}%`}
              y={`${100 - (value / 255) * 100}%`}
              width={`${100 / rectCount}%`}
              height={`${(value / 255) * 100}%`}
              fill="#2F4F4F" 
            />
          ))}
          {/* Render the played portion in a lighter shade */}
          {frequencyData.slice(0, rectCount).map((value, index) => (
            <rect
              key={`played-${index}`}
              x={`${(index / rectCount) * 100}%`}
              y={`${100 - (value / 255) * 100}%`}
              width={`${100 / rectCount}%`}
              height={`${(value / 255) * 100}%`}
              fill="url(#gradient)"
            />
          ))}
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#ceeff4" />
              <stop offset="100%" stopColor="#57828F" />
            </linearGradient>
          </defs>
        </svg>
      </Flex>

      <Flex alignItems="center" w="full">
        <IconButton
          aria-label={isPlaying ? 'Pause' : 'Play'}
          icon={isPlaying ? <FaPause /> : <FaPlay />}
          onClick={onPlayPause}
          mr={4}
        />
        <Text>
          {formatTime(currentTime)} / {formatTime(duration)}
        </Text>
        <Slider
          flex="1"
          mx={4}
          value={currentTime}
          max={duration}
          onChange={onSeek}
          aria-label="audio-progress-bar"
        >
          <SliderTrack bg="gray.200">
            <SliderFilledTrack bg="gunmetal.300" />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        <Flex alignItems="center">
          <FaVolumeUp />
          <Slider
            aria-label="volume-slider"
            value={volume}
            min={0}
            max={100}
            onChange={onVolumeChange}
            width="100px"
            ml={2}
          >
            <SliderTrack>
              <SliderFilledTrack bg="gunmetal.300" />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AudioPlayer;