import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  Flex,
  Icon,
  useDisclosure,
  Divider,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { MdInsertDriveFile } from 'react-icons/md';
import UploadCircularProgress from '../UploadCircularProgress/UploadCircularProgress';

const UploadMediaModal = ({ isOpen, onClose, onFileUpload }) => {
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [uploadData, setUploadData] = useState({
    loaded: 0,
    total: 0,
    timeLeft: 0,
  });

  const onDrop = acceptedFiles => {
    setFiles(acceptedFiles);
    if (onFileUpload) {
      onFileUpload(
        acceptedFiles[0],
        setProgress,
        setUploadData,
        setCompleted
      );
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
      <ModalOverlay />
      <ModalContent
        borderRadius={10}
        bg={'#232C33'}
        color={'white'}
        overflow={'hidden'}
      >
        <ModalHeader
          bg={'#314048'}
          borderColor={'#3E4E57'}
          borderBottomWidth={1}
        >
          Upload Image
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={5}>
          <Box
            {...getRootProps()}
            bg={'#1C242A'}
            border="1px solid"
            borderColor="#3E4E57"
            borderRadius="md"
            p={'40px'}
            textAlign="center"
            cursor="pointer"
            transition="all .3s ease-out"
            _hover={{ borderColor: 'beige.400', bg: '#314048' }}
          >
            <input {...getInputProps()} accept="image/*" />
            <Icon
              as={FaCloudUploadAlt}
              w={'24px'}
              h={'24px'}
              color="yellow.400"
            />
            <Text mt={2}>Choose an image or drag & drop it here.</Text>
            <Text fontSize="sm" color="gray.500">
              50MB max file size
            </Text>
          </Box>

          <Divider my={4} borderColor={'#3E4E57'} />

          {files.length > 0 && (
            <Box
              mt={4}
              p={4}
              bg={'#1C242A'}
              border="1px solid"
              borderColor="#3E4E57"
              borderRadius="md"
            >
              <Flex justifyContent="center" alignItems="center" wrap={'wrap'}>
                <Flex alignItems="center" w={'100%'}>
                  <Flex
                    align={'center'}
                    p={'10px'}
                    bg={'#232C33'}
                    borderRadius={'full'}
                  >
                    <Icon as={MdInsertDriveFile} w={8} h={8} />
                  </Flex>
                  <Box ml={3}>
                    <Text wordBreak={'break-word'}>{files[0].name}</Text>
                    <Text fontSize="sm" color="gray.500">
                      {completed ? 'Completed' : 'Uploading...'}
                    </Text>
                  </Box>
                </Flex>
                <Box ml={3}>
                  <UploadCircularProgress
                    progress={progress}
                    size="sm"
                    completed={completed}
                  />
                </Box>
              </Flex>
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            borderRadius={'full'}
            bg={'#1C242A'}
            border="1px solid"
            borderColor="#3E4E57"
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadMediaModal;
