import api from '../utils/api.utils';
import { handleResponse } from '../utils/app.utils';

// Adds audio to a project.
export const addAudioToProject = async ({
  projectId,
  scriptId,
  audioData,
  token,
}) => {
  console.log(
    'Adding audio to project:',
    projectId,
    scriptId,
    audioData,
  );

  const response = await api.post(
    `/audios/${projectId}/${scriptId}`,
    audioData,
    {
      customHeaders: { token },
    }
  );
  return handleResponse(response, 'Error adding audio to project');
};

// Removes an audio file from a project.
export const removeAudioFromProject = async ({
  projectId,
  scriptId,
  audioId,
  token,
}) => {

  const response = await api.delete(
    `/audios/${projectId}/${scriptId}/${audioId}`,
    {
      customHeaders: { token },
    }
  );
  return handleResponse(response, 'Error removing audio from project');
};

// Retrieves all audios for a specific user.
export const getAllAudiosForUser = async ({ userId, token }) => {
  const response = await api.get(`/audios/${userId}`, {
    customHeaders: { token },
  });
  return handleResponse(response, 'Error getting audios for user');
};

// Updates the audio with the specified audioId and projectId.
export const updateAudio = async (audioId, projectId, audioData, token) => {
  const response = await api.patch(
    `/audios/${projectId}/${audioId}`,
    audioData,
    {
      customHeaders: { token },
    }
  );
  return handleResponse(response, 'Error updating audio');
};

// Deletes an audio file from a project.
export const deleteAudio = async (audioId, projectId, token) => {
  const response = await api.delete(`/audios/${projectId}/${audioId}/delete`, {
    customHeaders: { token },
  });
  return handleResponse(response, 'Error deleting audio');
};
