import React, { useState, useEffect } from 'react';
import {
  Box,
  Link,
  Flex,
  VStack,
  Heading,
  Text,
  Divider,
  Stack,
  Image,
  Spacer,
  IconButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useDisclosure,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import {
  FaFeatherAlt,
  FaCog,
  FaFolderOpen,
  FaQuestion,
  FaSignOutAlt,
  FaTrophy,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';
import { AiFillDashboard } from 'react-icons/ai';
import useRecentProjects from '../../hooks/useRecentProjects';
import useGetProjects from '../../hooks/useGetProjects';
import Logo from '../../assets/Epicly-Logo-Wht.svg';
import { useUserContext } from '../../providers/UserProvider';
import LogoAnimation from '../LogoAnimation/LogoAnimation';

const Nav = () => {
  const { logout } = useUserContext();
  const { isOpen, onToggle } = useDisclosure();
  const [showHamburger, setShowHamburger] = useState(false);
  const navWidth = useBreakpointValue({ base: '100%', md: '64' });
  const showContent = useBreakpointValue({ base: false, md: true });

  const bgColor = useColorModeValue('#57828F', 'darkMode.bgSecondary');
  const textColor = useColorModeValue('#ffffff', 'darkMode.text');
  const iconColor = useColorModeValue('#ffffff', 'beige.300');
  const hoverBgColor = useColorModeValue('lightMode.secondary', 'darkMode.secondary');

  const handleResize = () => {
    setShowHamburger(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { recentProjects, loading: loadingRecent } = useRecentProjects();
  const { projects, isLoading: loadingProjects } = useGetProjects();

  const navItems = [
    {
      title: 'Dashboard',
      href: '/',
      icon: <AiFillDashboard />,
      isDisabled: false,
    },
    {
      title: 'Generate Ad',
      href: '/generate-ad',
      icon: <FaFeatherAlt />,
      isDisabled: false,
    },
  ];

  const libraryItems = [
    {
      title: 'Projects',
      href: '/projects',
      icon: <FaFolderOpen />,
      isDisabled: false,
      hasDropdown: false,
      dropdownItems: projects,
      loading: loadingProjects,
    },
    {
      title: 'Recent',
      href: '/projects/recent',
      icon: <FaFolderOpen />,
      isDisabled: false,
      hasDropdown: true,
      dropdownItems: recentProjects,
      loading: loadingRecent,
    },
  ];

  const navContent = (
    <VStack align="start" p={6} spacing={6} height={'100%'}>
      <Link href="/">
      <LogoAnimation />
      </Link>


      <Heading
        as="h2"
        size="sm"
        textTransform={'uppercase'}
        fontWeight={'normal'}
        color={textColor}
      >
        Menu
      </Heading>
      {navItems.map((navItem) => (
        <Flex direction="row" align="center" key={navItem.title}>
          <Box color={navItem.isDisabled ? 'gray.500' : iconColor}>{navItem.icon}</Box>
          {navItem.isDisabled ? (
            <Text color="gray.500" ml={4}>
              {navItem.title}
            </Text>
          ) : (
            <Link
              href={navItem.href}
              ml={4}
              color={textColor}
              _hover={{ color: hoverBgColor }}
            >
              {navItem.title}
            </Link>
          )}
        </Flex>
      ))}
      <Divider />
      <Stack>
        <Heading
          as="h2"
          size="sm"
          textTransform={'uppercase'}
          fontWeight={'normal'}
          color={textColor}
        >
          Library
        </Heading>
      </Stack>
      {libraryItems.map((libraryItem) => (
        <Box key={libraryItem.title} width="100%">
          {libraryItem.hasDropdown ? (
            <Accordion allowToggle>
              <AccordionItem border="none">
                {({ isExpanded }) => (
                  <>
                    <AccordionButton p={0}>
                      <Flex direction="row" align="center" width="100%">
                        <Box color={libraryItem.isDisabled ? 'gray.500' : iconColor}>
                          {libraryItem.icon}
                        </Box>
                        <Flex justify="space-between" align="center" width="100%">
                          <Link
                            href={libraryItem.href}
                            ml={4}
                            color={textColor}
                            _hover={{ color: hoverBgColor }}
                          >
                            {libraryItem.title}
                          </Link>
                          {isExpanded ? (
                            <FaChevronUp color={textColor} />
                          ) : (
                            <FaChevronDown color={textColor} />
                          )}
                        </Flex>
                      </Flex>
                    </AccordionButton>
                    <AccordionPanel pl={10} pt={2} pb={0}>
                      {libraryItem.loading ? (
                        <Text color="gray.500">Loading...</Text>
                      ) : libraryItem.dropdownItems && libraryItem.dropdownItems.length > 0 ? (
                        libraryItem.dropdownItems.map((project) => (
                          <Link
                            key={project._id}
                            href={`/project/${project._id}/edit`}
                            color={textColor}
                            _hover={{ color: hoverBgColor }}
                            display="block"
                            mb={2}
                          >
                            {project.title}
                          </Link>
                        ))
                      ) : (
                        <Text color="gray.500">No projects found.</Text>
                      )}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          ) : (
            <Flex direction="row" align="center">
              <Box color={libraryItem.isDisabled ? 'gray.500' : iconColor}>
                {libraryItem.icon}
              </Box>
              {libraryItem.isDisabled ? (
                <Text color="gray.500" ml={4}>
                  {libraryItem.title}
                </Text>
              ) : (
                <Link
                  href={libraryItem.href}
                  ml={4}
                  color={textColor}
                  _hover={{ color: hoverBgColor }}
                >
                  {libraryItem.title}
                </Link>
              )}
            </Flex>
          )}
        </Box>
      ))}
      <Spacer />
      <Flex flexDir={'column'} justify={'flex-end'} width={'100%'}>
        <Divider mb={5} />
        <Flex direction={'row'} align={'center'} mb={4}>
          <FaTrophy color={iconColor} />
          <Link
            href="/achievements"
            ml={4}
            color={textColor}
            _hover={{ color: hoverBgColor }}
          >
            Achievements
          </Link>
        </Flex>
        <Flex direction="row" align="center" mb={4}>
          <FaCog color={iconColor} />
          <Link
            href="/settings"
            ml={4}
            color={textColor}
            _hover={{ color: hoverBgColor }}
          >
            Settings
          </Link>
        </Flex>
        <Flex direction="row" align="center" mb={4}>
          <FaQuestion color={iconColor} />
          <Link
            href="/help"
            ml={4}
            color={textColor}
            _hover={{ color: hoverBgColor }}
          >
            Help
          </Link>
        </Flex>
        <Flex direction="row" align="center" mb={4}>
          <FaSignOutAlt color={iconColor} />
          <Link
            onClick={logout}
            ml={4}
            color={textColor}
            _hover={{ color: hoverBgColor }}
          >
            Logout
          </Link>
        </Flex>
      </Flex>
    </VStack>
  );

  return (
    <>
      {showHamburger ? (
        <>
          <IconButton
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            onClick={onToggle}
            position="fixed"
            top={8}
            left={5}
            zIndex={10}
            color={iconColor}
            bg={bgColor}
          />
          <Box
            pos="fixed"
            top="0"
            left="0"
            h="full"
            w="full"
            bg={bgColor}
            color={textColor}
            overflowY="auto"
            display={isOpen ? 'block' : 'none'}
          >
            {navContent}
          </Box>
        </>
      ) : (
        <Box
          as="nav"
          pos="fixed"
          top="0"
          left="0"
          h="full"
          w={navWidth}
          bg={bgColor}
          color={textColor}
          display={showContent ? 'block' : 'none'}
        >
          {navContent}
        </Box>
      )}
    </>
  );
};

export default Nav;
