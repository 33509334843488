import { useState, useCallback } from 'react';
import { getScriptTopicSuggestions } from '../services/ai.service';
import { useUserContext } from '../providers/UserProvider';

const useScriptTopicSuggestions = () => {
  const { token } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getSuggestions = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await getScriptTopicSuggestions(data, token);
      console.log('Script Ideas Response in hook', response);
      const suggestions = response.suggestions.adIdeas;
      
      if (response.xpEarned) {
        console.log('Hook Regeneration');
        const xpEarned = response.xpEarned;
        const creditAmount = response.creditTransaction.amount;

        return { suggestions: suggestions, xpEarned, creditAmount };
      }
      console.log('Hook Initial Generation');
      return { suggestions };
    } catch (err) {
      setError(err.message);
      return [];
    } finally {
      setLoading(false);
    }
  }, [token]);

  return { getSuggestions, error, loading };
};

export default useScriptTopicSuggestions;
