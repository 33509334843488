import axios from 'axios';
import { config } from '../config/config';

const api = axios.create({
  baseURL: config.BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  config => {
    const token = config.customHeaders?.token;
    const contentType = config.customHeaders?.contentType;

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    
    if (contentType) {
      config.headers['Content-Type'] = contentType;
    }
    
    delete config.customHeaders;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default api;
