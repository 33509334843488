import React, { useState } from 'react';
import {
  Box,
  Text,
  VStack,
  Heading,
  Button,
  IconButton,
  Skeleton,
  useColorModeValue,
  HStack,
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';

const ScriptIdeas = ({ suggestions, onSelectIdea, onRegenerate, loading }) => {
  // Color mode values
  const bgColor = useColorModeValue('beige.200', 'gunmetal.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.100', 'gunmetal.500');
  const buttonBgColor = useColorModeValue('beige.400', 'gunmetal.500');
  const buttonHoverBgColor = useColorModeValue('beige.300', 'gunmetal.400');
  const buttonTextColor = useColorModeValue('white', 'white');
  const iconButtonBgColor = useColorModeValue('beige.100', 'whiteAlpha.200');
  const iconButtonHoverBgColor = useColorModeValue('white', 'whiteAlpha.300');

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  // Calculate the index of the last item on the current page
  const indexOfLastIdea = currentPage * itemsPerPage;
  // Calculate the index of the first item on the current page
  const indexOfFirstIdea = indexOfLastIdea - itemsPerPage;
  // Get the current ideas to display
  const currentIdeas = suggestions.slice(indexOfFirstIdea, indexOfLastIdea);

  // Calculate total pages
  const totalPages = Math.ceil(suggestions.length / itemsPerPage);

  return (
    <Box mt={8} bg={bgColor} color={textColor} p={4} borderRadius="md">
      <HStack mb={4} justifyContent="space-between" align="center">
        <Heading as="h2" size="lg">
          Script Ideas
        </Heading>
        <HStack>
          <IconButton
            icon={<RepeatIcon />}
            onClick={onRegenerate}
            ml={4}
            mr={2}
            isLoading={loading}
            bg={iconButtonBgColor}
            _hover={{ bg: iconButtonHoverBgColor }}
          />
          <Text>Costs 1 credit</Text>
        </HStack>
      </HStack>

      <VStack spacing={4} align="start">
        {loading
          ? Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} height="125px" width="100%" />
            ))
          : currentIdeas.map((suggestion, index) => (
              <Box
                key={index}
                p={4}
                border="1px solid"
                borderColor={borderColor}
                borderRadius="md"
                w="full"
                bg={bgColor}
              >
                <Text fontWeight="bold">{suggestion.title}</Text>
                <Text>{suggestion.description}</Text>
                <Button
                  mt={2}
                  onClick={() => onSelectIdea(suggestion)}
                  bg={buttonBgColor}
                  _hover={{ bg: buttonHoverBgColor }}
                  color={buttonTextColor}
                >
                  Select
                </Button>
              </Box>
            ))}
      </VStack>

      {/* Pagination Controls */}
      <HStack spacing={4} mt={4} justifyContent="center">
        <Button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          isDisabled={currentPage === 1}
        >
          Previous
        </Button>
        <Text>
          Page {currentPage} of {totalPages}
        </Text>
        <Button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          isDisabled={currentPage === totalPages}
        >
          Next
        </Button>
      </HStack>
    </Box>
  );
};

export default ScriptIdeas;
