import { useEditableControls } from '@chakra-ui/react';
import { ButtonGroup, IconButton, Flex } from '@chakra-ui/react';
import { CheckIcon, CloseIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';

const EditableControls = ({ onDelete }) => {

    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();
  
    return isEditing ? (
      <ButtonGroup justifyContent="end" size="sm" spacing={2}>
        <IconButton 
            icon={<CheckIcon />} 
            {...getSubmitButtonProps()} 
            _hover={{ bg: 'green.500' }}
            aria-label="Save shot"
        />
        
        <IconButton 
            icon={<CloseIcon />} 
            {...getCancelButtonProps()} 
            _hover={{ bg: 'red.500' }}
            aria-label="Cancel shot"
        />
      </ButtonGroup>

    ) : (
      <Flex justifyContent="end" gap={2}>
        <IconButton 
            size="sm" 
            icon={<EditIcon />}
            _hover={{ bg: 'beige.300' }}
            {...getEditButtonProps()}           
            aria-label="Edit shot"
        />
  

        <IconButton
              size="sm"
              icon={<DeleteIcon />}
              onClick={onDelete}
              _hover={{ bg: 'red.500' }}
              aria-label="Delete shot"
        />
      </Flex>
    );
  };

export default EditableControls;