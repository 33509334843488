import React from 'react';
import { useColorMode, useColorModeValue, IconButton, Tooltip } from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';

export const ColorModeSwitcher = props => {
  const { toggleColorMode } = useColorMode();

  const bgColor = useColorModeValue('#57828F', 'gunmetal.500');
  const hoverBgColor = useColorModeValue('beige.300', 'gunmetal.400');
  const textColor = useColorModeValue('beige.100', 'gunmetal.100');
  const text = useColorModeValue('dark', 'light');
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  return (
    <Tooltip label={`Switch to ${text} mode`} aria-label={`Switch to ${text} mode`}>

<IconButton
      size={props.size}
      fontSize="lg"
      aria-label={`Switch to ${text} mode`}
      variant="ghost"
      onClick={toggleColorMode}
      bg={bgColor}
      color={textColor}
      _hover={{ bg: hoverBgColor }}
      icon={<SwitchIcon />}
      isRound
      {...props}
    />
    </Tooltip>

  );
};
