export const handleResponse = (response, message) => {
  if (!response.status === 200) {
    throw new Error(message);
  }
  return response.data;
};

export const refreshUserCredits = (user, setUser, amount) => {
  if (amount < 0) {
    const spendAmount = -amount; // Convert to positive value

    let subscriptionCredits = user.subscriptionCredits || 0;
    let purchasedCredits = user.purchasedCredits || 0;
    let remainingAmount = spendAmount;

    // Deduct from subscription credits first
    if (subscriptionCredits >= remainingAmount) {
      subscriptionCredits -= remainingAmount;
      remainingAmount = 0;
    } else {
      remainingAmount -= subscriptionCredits;
      subscriptionCredits = 0;

      // Deduct from purchased credits if available
      if (purchasedCredits >= remainingAmount) {
        purchasedCredits -= remainingAmount;
        remainingAmount = 0;
      } else {
        // Not enough credits, do nothing
        return;
      }
    }

    // Update the user state
    setUser(prevUser => ({
      ...prevUser,
      subscriptionCredits,
      purchasedCredits,
    }));
  } else if (amount > 0) {
    // If earning, add to purchased credits
    setUser(prevUser => ({
      ...prevUser,
      purchasedCredits: (prevUser.purchasedCredits || 0) + amount,
    }));
  }
};

export const refreshUserXp = (setUser, amount) => {
  setUser(prevUser => ({
    ...prevUser,
    xp: prevUser.xp + amount,
  }));
};

export const formatPrice = (amount, currency) => {
  const price = amount / 100;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(price);
};

export function countWordsInArray(array) {
  let wordCount = 0;

  array.forEach(item => {
    if (item.visual) {
      wordCount += countWordsInString(item.visual);
    }
    if (item.audio) {
      wordCount += countWordsInString(item.audio);
    }
  });

  return wordCount;
}

export function countWordsInString(str) {
  const words = str.match(/\S+/g); // words according to whitespace
  return words ? words.length : 0;
}

export function estimateVideoDuration(wordCount) {
  const wordsPerMinute = 155; // Average speech pace

  // Calculate duration in minutes
  const durationInMinutes = wordCount / wordsPerMinute;

  // Calculate whole minutes and remaining seconds
  const wholeMinutes = Math.floor(durationInMinutes);
  const remainingSeconds = Math.round((durationInMinutes - wholeMinutes) * 60);

  // Format minutes and seconds to two digits
  const formattedMinutes = String(wholeMinutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
}

export const durationToSMPTE = (words, fps, wordsPerMinute) => {
  // Calculate duration in seconds
  const durationInSeconds = (words / wordsPerMinute) * 60;

  // Total frames
  const totalFrames = Math.round(durationInSeconds * fps);

  // Calculate hours, minutes, seconds, and frames
  const hours = Math.floor(totalFrames / (3600 * fps));
  const minutes = Math.floor((totalFrames % (3600 * fps)) / (60 * fps));
  const seconds = Math.floor((totalFrames % (60 * fps)) / fps);
  const frames = totalFrames % fps;

  // Format components with leading zeros
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');
  const formattedFrames = String(frames).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}:${formattedFrames}`;
};

export const totalDuration = (shots, fps, wordsPerMinute) => {
  let totalWords = 0;

  shots.forEach(shot => {
    // Extract and concatenate dialogue content
    const dialogue = shot.audio
      .filter(item => item.type === 'DIALOGUE')
      .map(item => item.content)
      .join(' ');

    // Count words in the dialogue
    const words = dialogue.split(/\s+/).filter(word => word.length > 0).length;
    totalWords += words;
  });

  // Convert total word count to SMPTE timecode
  return durationToSMPTE(totalWords, fps, wordsPerMinute);
};

export const parseTargetDuration = targetDuration => {
  if (targetDuration.endsWith('s')) {
    return parseInt(targetDuration.slice(0, -1), 10);
  }
  return 0;
};

// Function to convert SMPTE to seconds
export const smpteToSeconds = (smpte, fps) => {
  const [hours, minutes, seconds, frames] = smpte.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds + frames / fps;
};

export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

// Calculate the discounted price based on the list price and discount percentage.
export const calculateDiscountedPrice = (price, discount) => {
  const discountFactor = (100 - discount) / 100;
  return price * discountFactor;
};

export const formatDate = (date) => {
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  }).replace(/[/,:\s]/g, '-');
};

export const formatDateFromUTC = (date) => {
  return new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const formatFileName = (title, type, date) => {
  const formattedTitle = title.replace(/\s+/g, '-').toLowerCase();
  return `${formattedTitle}-${date}.${type}`;
};

  // Function to ensure proper spacing
  export const ensureProperSpacing = text => {
    const regex = /([.,!?])([^\s]|$)/g;
    const correctedText = text.replace(regex, '$1 $2');
    return correctedText;
  };