import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  Heading,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  FaCog,
  FaFileAlt,
  FaHeadphones,
  FaMusic,
  FaUpload,
} from 'react-icons/fa';

const WelcomeModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showModal, setShowModal] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const bgColor = useColorModeValue('lightMode.bgSecondary', 'darkMode.bg');
  const textColor = useColorModeValue('lightMode.text', 'darkMode.text');
  const secondaryTextColor = useColorModeValue('lightMode.textSecondary', 'darkMode.textSecondary');
  const iconBgColor = useColorModeValue('lightMode.textSecondary', 'gunmetal.500');
  const iconColor = useColorModeValue('beige.200', 'gunmetal.300');
  const linkColor = useColorModeValue('lightMode.link', 'darkMode.link');

  useEffect(() => {
    const modalPreference = localStorage.getItem('showWelcomeModal');
    if (modalPreference !== 'false') {
      setShowModal(true);
      onOpen();
    }
  }, [onOpen]);

  const handleCheckboxChange = () => {
    setDontShowAgain(!dontShowAgain);
  };

  const handleClose = () => {
    if (dontShowAgain) {
      localStorage.setItem('showWelcomeModal', 'false');
    }
    setShowModal(false);
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen && showModal}
        onClose={handleClose}
        size="4xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent bg={bgColor} color={textColor} borderRadius="md" px={4}>
          <ModalHeader>
            <Flex direction="column" align="center" mt={8}>
              <Heading size={{ base: 'md', md: 'lg' }}>
                How Epicly Works
              </Heading>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" align="start" position="relative" mt={4}>
              <Box
                position="absolute"
                left={{ base: '0', md: '32px' }}
                top={{ base: '0', md: '48px' }}
                bottom="16px"
                w={{ base: '0', md: '2px' }}
                bg={{ base: 'none', md: secondaryTextColor }}
                zIndex={0}
              ></Box>
              <Stack spacing={8} zIndex={1}>
                {steps.map((step, index) => (
                  <Flex
                    key={index}
                    align="center"
                    direction={{ base: 'column', md: 'row' }}
                    zIndex={1}
                  >
                    <Flex
                      p={2}
                      justify="center"
                      align="center"
                      w={'64px'}
                      h={'64px'}
                      borderRadius="full"
                      bg={iconBgColor}
                      position="relative"
                      zIndex={1}
                      mb={{ base: 4, md: 0 }}
                    >
                      <Icon as={step.icon} w={5} h={5} color={iconColor} />
                    </Flex>

                    <VStack
                      align="stretcht"
                      spacing={2}
                      ml={{ base: 0, md: 20 }}
                      textAlign={{ base: 'center', md: 'left' }}
                    >
                      <Heading as="h3" size="md">
                        {step.title}
                        {step.optional && (
                          <Text as="span" fontSize="md" fontWeight="normal">
                            {' '}
                            (optional)
                          </Text>
                        )}
                      </Heading>
                      <Text maxW="350px" color={secondaryTextColor}>
                        {step.description}
                      </Text>
                      {step.learnMore && (
                        <Link color={linkColor} href={step.link} textAlign={{base: 'center', md: 'left'}} isExternal>
                          Learn more
                        </Link>
                      )}
                    </VStack>
                  </Flex>
                ))}
              </Stack>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Checkbox
              colorScheme="teal"
              isChecked={dontShowAgain}
              onChange={handleCheckboxChange}
            >
              Don’t show this again
            </Checkbox>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const steps = [
  {
    title: 'Step 1: Setup your Project',
    description:
      'This helps determine the type of project, theme, tone, and other details.',
    icon: FaCog,
  },
  {
    title: 'Step 2: Craft your Script',
    description:
      'Once all the project details are locked down, we are ready to generate your script for further refinement.',
    icon: FaFileAlt,
  },
  {
    title: 'Step 3: Generate your Voiceover',
    description:
      'Once your script is edited to your liking, we can then generate a voiceover from a selection of 12 realistic voices.',
    learnMore: true,
    optional: true,
    link: '/learn-more-voiceovers',
    icon: FaHeadphones,
  },
  {
    title: 'Step 4: Create or Upload your Music',
    description:
      'Once your script is edited to your liking, we can then generate a voiceover from a selection of 12 realistic voices.',
    learnMore: true,
    optional: true,
    link: '/learn-more-music',
    icon: FaMusic,
  },
  {
    title: 'Step 5: Export',
    description:
      'You’re well on your way now. Export your script and voiceover to get rolling on your visuals.',
    icon: FaUpload,
  },
];

export default WelcomeModal;
