import React from 'react';
import {
  Box,
  VStack,
  Text,
  Progress,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  HStack,
  Icon,
  useColorMode,
  Flex,
  Skeleton,
  Spinner,
} from '@chakra-ui/react';
import { FaStar, FaTrophy, FaGift, FaLock } from 'react-icons/fa';
import { LEVEL_REWARDS } from '../../config/config';
import useRewards from '../../hooks/useRewards';

const LevelProgress = ({ level, xp, xpForNextLevel }) => {
  const { redeemedRewards, handleRedeem, loadingRewards } = useRewards();
  const { colorMode } = useColorMode();
  const progress = (xp / xpForNextLevel) * 100;

  return (
    <Box w="full" maxW="md" p={2} bg="gunmetal.500" rounded="lg" shadow="md">
      {loadingRewards ? (
        <Flex align="center" justify="center">
          <Spinner />
        </Flex>
      ) : (
        <Popover>
          <PopoverTrigger>
            <VStack spacing={2}>
              <Box textAlign="center">
                <Text
                  fontSize="md"
                  fontWeight="bold"
                  color="beige.200"
                  cursor="pointer"
                  mb={0}
                  _hover={{
                    color: 'white',
                  }}
                  transition="all 0.2s ease-in-out"
                >
                  Level {level}
                </Text>
                <Text
                  fontSize="sm"
                  color={colorMode === 'light' ? 'beige.200' : 'gunmetal.200'}
                >
                  XP: {xp} / {xpForNextLevel}
                </Text>
              </Box>
              <Progress value={progress} size="md" w="full" rounded="full" />
            </VStack>
          </PopoverTrigger>
          <PopoverContent
            w="80"
            bg={colorMode === 'light' ? 'beige.400' : 'gunmetal.700'}
          >
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader
              textAlign="center"
              fontWeight="bold"
              color={colorMode === 'light' ? 'white' : 'beige.200'}
            >
              Level Milestone Rewards
            </PopoverHeader>

            <PopoverBody maxH="350px" overflowY="auto">
              <VStack spacing={2} align="stretch">
                {LEVEL_REWARDS.map((reward, index) => {
                  const isUnlocked = level >= reward.level;
                  const isRedeemed = redeemedRewards.includes(reward.level);
                  const isRedeemable = isUnlocked && !isRedeemed;

                  return (
                    <Box key={index}>
                      <HStack
                        p={2}
                        rounded="md"
                        bg={isUnlocked ? 'gunmetal.500' : 'gunmetal.800'}
                        spacing={3}
                        opacity={isUnlocked ? 1 : 0.6}
                      >
                        <Icon
                          as={
                            isRedeemed
                              ? FaStar
                              : isRedeemable
                              ? FaTrophy
                              : FaLock
                          }
                          color={
                            isRedeemed
                              ? 'yellow.400'
                              : isRedeemable
                              ? 'yellow.400'
                              : 'gunmetal.200'
                          }
                          boxSize={5}
                        />
                        <VStack align="start" spacing={0} flex="1">
                          <Text
                            fontSize="sm"
                            fontWeight="medium"
                            color={isUnlocked ? 'white' : 'gunmetal.200'}
                          >
                            Level {reward.level}
                          </Text>
                          <Text fontSize="xs" color="gunmetal.200">
                            {reward.reward}
                          </Text>
                          <Text fontSize="xs" color="gunmetal.200">
                            +{reward.creditReward} Credits
                          </Text>
                        </VStack>
                        {isRedeemable && (
                          <Button
                            size="sm"
                            bg="gunmetal.550"
                            color="white"
                            _hover={{
                              bg: 'gunmetal.600',
                            }}
                            onClick={() => handleRedeem(reward.level)}
                          >
                            Redeem
                          </Button>
                        )}
                        {isRedeemed && (
                          <Icon as={FaStar} color="yellow.400" boxSize={5} />
                        )}
                      </HStack>
                    </Box>
                  );
                })}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </Box>
  );
};

export default LevelProgress;
