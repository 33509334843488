import { useCallback, useEffect, useState } from "react";
import { useUserContext } from "../providers/UserProvider";
import { getVoiceActors as getVOActors } from "../services/voiceActors.service";

export const useGetVoiceActors = () => {
    const context = useUserContext();

    const token = context?.token;
    
    const [voiceActors, setVoiceActors] = useState([]);
    const [loading, setLoading] = useState(false);

    const getVoiceActors = useCallback(async () => {
        if (!token) return;

        try {
            setLoading(true);
            const fetchedVoiceActors = await getVOActors(token);
            setVoiceActors(fetchedVoiceActors);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, [setLoading, token, setVoiceActors]);

    useEffect(() => {
        getVoiceActors();
    }, [getVoiceActors]);

    return { voiceActors, getVoiceActors, loading };
};
