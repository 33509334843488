import { useState, useEffect } from 'react';
import { useUserContext } from '../providers/UserProvider';
import { config } from '../config/config';
import api from '../utils/api.utils';

const useRewards = () => {
  const { user, token, refreshUserData } = useUserContext();
  const [redeemedRewards, setRedeemedRewards] = useState([]);
  const [loadingRewards, setLoadingRewards] = useState(true);

  useEffect(() => {
    if (user && user.redeemedRewards) {
      setRedeemedRewards(user.redeemedRewards.map((reward) => reward.level));
    }
    setLoadingRewards(false);
  }, [user]);

  const handleRedeem = async (levelToRedeem) => {
    try {
      await api.post(
        `${config.BACKEND_URL}/rewards/${user._id}/rewards/redeem`,
        { level: levelToRedeem },
        {
          customHeaders: { token },
        }
      );
      // Update redeemed rewards after successful redemption
      setRedeemedRewards((prevRewards) => [...prevRewards, levelToRedeem]);
      // Refresh user data to update credits and other info
      await refreshUserData();
    } catch (error) {
      console.error('Error redeeming reward:', error);
    }
  };

  return { redeemedRewards, handleRedeem, loadingRewards };
};

export default useRewards;

