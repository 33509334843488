import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  useBreakpointValue,
} from '@chakra-ui/react';

const CreateProjectModal = ({
  isOpen,
  onClose,
  onCreateProject,
  isLoading,
}) => {
  const [projectName, setProjectName] = useState('Untitled Project');

  const handleSubmit = useCallback(async () => {
    const success = await onCreateProject(projectName);
    if (success) {
      setProjectName('');  // Reset project name after successful creation
      onClose();
    }
  }, [projectName, onCreateProject, onClose]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && isOpen && !isLoading) {
        handleSubmit();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, isLoading, handleSubmit]);

  const modalPadding = useBreakpointValue({ base: 2, md: 6 });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={'gunmetal.600'} p={modalPadding}>
        <ModalHeader color={'white'}>New Project</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel color={'beige.300'}>Project Name</FormLabel>
            <Input
              value={projectName}
              onChange={e => setProjectName(e.target.value)}
              isDisabled={isLoading}
              color={'white'}
              placeholder="Untitled Project"
              size={useBreakpointValue({ base: 'sm', md: 'md' })}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            mr={3}
            onClick={onClose}
            isDisabled={isLoading}
            color={'white'}
            size={useBreakpointValue({ base: 'sm', md: 'md' })}
          >
            Close
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={isLoading}
            size={useBreakpointValue({ base: 'sm', md: 'md' })}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateProjectModal;