import { useState, useEffect, useCallback, useMemo } from 'react';
import { createReferral, getUserReferrals, deleteReferral, completeReferral } from '../services/referral.service';
import { useUserContext } from '../providers/UserProvider';
import { useToast } from '@chakra-ui/react';

const useReferral = () => {
  const { user, token } = useUserContext();
  const [referrals, setReferrals] = useState([]);
  const [referralLink, setReferralLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const toast = useToast();

  const fetchReferrals = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getUserReferrals({ userId: user._id, token });
      setReferrals(response.referrals);
      setReferralLink(response.referralLink);
      setError(null);
    } catch (err) {
      setError(err.message);
      toast({
        title: "Error",
        description: `Failed to fetch referrals: ${err.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [user._id, token, toast]);

  const createNewReferral = useCallback(async (referredEmail) => {
    setIsLoading(true);
    try {
      const { referral, referralLink } = await createReferral({ referrerId: user._id, referredEmail, token });
      setReferrals(prevReferrals => [...prevReferrals, {
        _id: referral,
        referredUser: { email: referredEmail },
        status: 'pending',
        referralLink
      }]);
      setReferralLink(referralLink);
      setError(null);
      toast({
        title: "Referral created",
        description: "Your referral was created successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      return { referral, referralLink };
    } catch (err) {
      setError(err.message);
      toast({
        title: "Error",
        description: `Failed to create referral: ${err.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, [user._id, token, toast]);

  const deleteExistingReferral = useCallback(async (referralId) => {
    setIsLoading(true);
    try {
      await deleteReferral({ userId: user._id, referralId, token });
      await fetchReferrals(); // Refresh the referrals list after deleting one
      setError(null);
      toast({
        title: "Referral deleted",
        description: "The referral was deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      setError(err.message);
      toast({
        title: "Error",
        description: `Failed to delete referral: ${err.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [user._id, token, toast, fetchReferrals]);

  const completeExistingReferral = useCallback(async (referralCode, referredUserEmail) => {
    setIsLoading(true);
    try {
      await completeReferral({ referralCode, referredUserEmail, token });
      await fetchReferrals(); // Refresh the referrals list after completing one
      setError(null);
      toast({
        title: "Referral completed",
        description: "The referral was completed successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      setError(err.message);
      toast({
        title: "Error",
        description: `Failed to complete referral: ${err.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [token, toast, fetchReferrals]);

  useEffect(() => {
    if (user && token) {
      fetchReferrals();
    }
  }, [user, token, fetchReferrals]);

  return useMemo(() => ({
    referrals,
    referralLink,
    createNewReferral,
    deleteExistingReferral,
    completeExistingReferral,
    isLoading,
    error,
    refreshReferrals: fetchReferrals,
  }), [referrals, referralLink, createNewReferral, deleteExistingReferral, isLoading, error, fetchReferrals]);
};

export default useReferral;
