import { useCallback, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useUserContext } from '../providers/UserProvider';
import { deleteProject as deleteProjectService } from '../services/project.service';

const useDeleteProject = () => {
  const { token } = useUserContext();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const deleteProject = useCallback(
    async projectId => {
      setLoading(true);
      try {
          const response = await deleteProjectService(projectId, token);

          return response;
      } catch (error) {
        toast({
          title: 'Error deleting project.',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        return null;
      } finally {
        setLoading(false);
      }
    },
    [token, toast]
  );

  return { deleteProject, loading };
};

export default useDeleteProject;
