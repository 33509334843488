import React from 'react';
import {
  HStack,
  IconButton,
  Heading,
  VStack,
  Flex,
  Text,
  Button,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { ChevronLeftIcon, EditIcon } from '@chakra-ui/icons';

const ScriptEditorHeader = ({
  scriptTitle,
  targetDuration,
  currentDuration,
  timeOverLimit,
  onBack,
  onSave,
  onEditDuration,
  saveLoading,
  voiceoverLoading,
}) => {
  // Colors
  const borderColor = useColorModeValue('gray.200', 'gunmetal.550');
  const headerBgColor = useColorModeValue('white', 'gunmetal.600');
  const iconBgColor = useColorModeValue('gray.200', 'gunmetal.500');
  const iconHoverBgColor = useColorModeValue('gray.300', 'gunmetal.400');
  const textColor = useColorModeValue('gray.800', 'white');
  const subTextColor = useColorModeValue('gray.600', 'gray.400');
  const errorColor = useColorModeValue('red.500', 'red.300');
  const buttonBgColor = useColorModeValue('beige.300', 'beige.300');
  const buttonHoverBgColor = useColorModeValue('beige.400', 'beige.400');

  return (
    <HStack
      px={4}
      py={4}
      w="full"
      spacing={4}
      border="1px solid"
      borderColor={borderColor}
      shadow={'md'}
      alignItems="center"
      bg={headerBgColor}
      borderRadius={'md'}
      mb={8}
    >
      {/* Back Button */}
      <IconButton
        aria-label="Back"
        icon={<ChevronLeftIcon />}
        onClick={onBack}
        bg={iconBgColor}
        _hover={{ bg: iconHoverBgColor }}
      />

      {/* Script Name */}
      <Heading
        fontSize={['xs', 'sm', '2xl', '2xl']}
        flex={1}
        textAlign="left"
        fontWeight={'700'}
        color={textColor}
      >
        {`"${scriptTitle}"` || 'Loading...'}
      </Heading>

      <VStack align={'stretch'} justify={'stretch'} mr={4}>
        <Flex align={'center'} justify={'stretch'} gap={2}>
          <Text color={subTextColor} textAlign={'left'}>
            Target Duration: {targetDuration || 'Loading...'}
          </Text>
          <IconButton
            icon={<EditIcon />}
            aria-label="Edit"
            size="xs"
            borderRadius={'md'}
            onClick={onEditDuration}
            bg={iconBgColor}
            _hover={{ bg: iconHoverBgColor }}
          />
        </Flex>

        {timeOverLimit ? (
          <Tooltip
            label="The current duration exceeds the target duration"
            aria-label="The current duration exceeds the target duration"
          >
            <Text color={errorColor}>
              Current Duration:{' '}
              <span style={{ color: errorColor }}>{currentDuration}</span>
            </Text>
          </Tooltip>
        ) : (
          <Text color={subTextColor}>
            Est. Current Duration: {currentDuration}
          </Text>
        )}
      </VStack>

      {/* Save Button */}
      <Button
        onClick={onSave}
        loadingText="Saving..."
        isLoading={saveLoading || voiceoverLoading}
        bg={buttonBgColor}
        _hover={{ bg: buttonHoverBgColor }}
      >
        Save
      </Button>
    </HStack>
  );
};

export default ScriptEditorHeader;
