import { useCallback, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { updateProject } from '../services/project.service';
import { useUserContext } from '../providers/UserProvider';

const useUpdateProject = (projectDetails) => {
  const { token } = useUserContext();
  const [hookLoading, setHookLoading] = useState(false);
  const toast = useToast();

  const updateProjectData = useCallback(async (projectDetails) => {
    console.log('Attempting to update project:', projectDetails);
    setHookLoading(true);

    try {
      const updatedProject = await updateProject(
        projectDetails._id,
        projectDetails,
        token
      );
      console.log('Updated Project Details', updatedProject);

      // Show success toast
      toast({
        title: 'Project updated.',
        description: `Project has been updated.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      // Show error toast
      toast({
        title: 'An error occurred.',
        description: `Unable to update project. ${error.message}`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setHookLoading(false);
    }
  }, [toast, token]);

  return { updateProjectData, hookLoading, refetch: updateProjectData };
};

export default useUpdateProject;
