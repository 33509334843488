import { useCallback, useState } from 'react';
import { generateScript } from '../services/ai.service';
import { useUserContext } from '../providers/UserProvider';
import { useToast } from '@chakra-ui/react';

const useGenerateScript = () => {
  const { token } = useUserContext();
  const [hookLoading, setHookLoading] = useState(false);
  const toast = useToast();

  const generateScriptData = useCallback(async (data) => {
    setHookLoading(true);
    try {
      const newScript = await generateScript(data, token);
      console.log('Generated Script Details', newScript);

      // Show success toast
      toast({
        title: 'Script generated.',
        description: `Script has been generated.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      return newScript; // Return the script details
    } catch (error) {
      // Show error toast
      toast({
        title: 'An error occurred.',
        description: `Unable to generate script. ${error.message}`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      throw error; // Rethrow the error to handle it in the component if needed
    } finally {
      setHookLoading(false);
    }
  }, [token, toast]);

  return { generateScriptData, hookLoading };
};

export default useGenerateScript;
