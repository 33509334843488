import React, { useEffect } from 'react';
import { Text, Stack, Icon, Flex, Switch, FormControl, FormLabel } from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useSignupFormContext } from '../../providers/SignupFormProvider';

const MotionFlex = motion(Flex);

const variants = {
    initial: { opacity: 0, x: 50 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -50 },
};

const ProfileSetupOptins = ({ step }) => {
    const { marketingOptIn, setMarketingOptIn, enhanceApp, setEnhanceApp } = useSignupFormContext();

    useEffect(() => {
        console.log('Marketing Opt In:', marketingOptIn);
        console.log('Enhance App:', enhanceApp);
    }, [marketingOptIn, enhanceApp]);

    return (
        <MotionFlex
            flexDir={'column'}
            justify={'center'}
            align={'stretch'}
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.5 }}
            flex={1}
            p={{ base: 4, md: 8 }}
        >
            <Stack direction={{ base: 'column', md: 'row' }} align={'center'} mb={4}>
              <Text fontSize={{ base: 'xl', md: '2xl' }}>{step - 1}</Text>
              <Icon as={FaArrowRight} w={{ base: 4, md: 6 }} h={{ base: 4, md: 6 }} color="gunmetal.300" mr={{ base: 2, md: 4 }} />
              <Text fontSize={{ base: 'lg', md: '2xl' }} textAlign={{ base: 'center', md: 'left' }}>
                Opt in to marketing emails and help enhance our app.
              </Text>
            </Stack>

            <Text fontSize={{ base: 'sm', md: 'md' }} mb={8} color={'gray.300'} textAlign={{ base: 'center', md: 'left' }}>
              We respect your privacy.
            </Text>

            <FormControl display="flex" alignItems="center" mt={4}>
              <FormLabel mb="0" fontSize={{ base: 'md', md: 'lg' }} textAlign={{ base: 'center', md: 'left' }}>Opt in to marketing emails</FormLabel>
              <Switch
                size="lg"
                onChange={() => setMarketingOptIn(!marketingOptIn)}
                isChecked={marketingOptIn}
                ml={4}
              />
            </FormControl>

            <FormControl display="flex" alignItems="center" mt={8}>
              <FormLabel mb="0" fontSize={{ base: 'md', md: 'lg' }} textAlign={{ base: 'center', md: 'left' }}>Help enhance our app</FormLabel>
              <Switch
                size="lg"
                onChange={() => setEnhanceApp(!enhanceApp)}
                isChecked={enhanceApp}
                ml={4}
              />
            </FormControl>
            <Text fontSize={{ base: 'xs', md: 'sm' }} color={'gray.100'} mt={2} textAlign={{ base: 'center', md: 'left' }}>
              Allow us to use your anonymized outputs to improve our services.
            </Text>
        </MotionFlex>
    );
}

export default ProfileSetupOptins;
