import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Button,
  VStack,
  useColorModeValue,
  Icon,
  HStack,
  Heading,
  Divider,
  Skeleton,
} from '@chakra-ui/react';
import { LuPackage, LuPartyPopper, LuZap } from "react-icons/lu";

// Purchase Product Success Modal
const PurchaseModal = ({ isOpen, onClose, title, message, credits, xp, loading }) => {

    
    const bgColor = useColorModeValue('beige.100', '#232C33');
    const headerBgColor = useColorModeValue('beige.200', '#314048');
    const borderColor = useColorModeValue('gray.300', '#3E4E57');
    const textColor = useColorModeValue('gray.800', 'white');
    const headerIconColor = useColorModeValue('gray.800', 'yellow.400');
    const Icon2Color = useColorModeValue('orange.500', '#FFB223');
    const Icon1Color = useColorModeValue('green.500', '#00FF00');
    const subHeadingColor = useColorModeValue('gray.600', 'white');
    const bulletColor = useColorModeValue('gray.400', 'gray.400');



  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bg={bgColor}
        borderColor={borderColor}
        borderRadius={'lg'}
        borderWidth={'1px'}
        overflow={'hidden'}
      >
        <ModalHeader bg={headerBgColor} color={textColor} textAlign={'center'}>
          <HStack spacing={4} alignItems={'center'} justifyContent={'center'}>
            <Heading fontSize={'2xl'} fontWeight={'bold'}>
              {title}
            </Heading>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={8} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}  gap={8}>
          {loading ? (
            <VStack spacing={4}>
              <Skeleton height="50px" width="50px" borderRadius="full" />
              <Skeleton height="20px" width="200px" />
              <Skeleton height="20px" width="150px" />
            </VStack>
          ) : (
            <>
              <Icon as={LuPartyPopper} fontSize={'5rem'} color={headerIconColor} />
              <Text fontSize={'lg'} fontWeight={'bold'} textAlign={'center'} color={subHeadingColor}>
                {message}
              </Text>
              <VStack gap={4} flex={1} w={'full'}>
                <HStack w={'full'} justifyContent={'center'} alignItems={'center'}>
                  <Icon as={LuPackage} fontSize={'1.5rem'} color={Icon1Color} />
                  <Text fontSize={'lg'} color={bulletColor}>
                    {credits || 0} Credits added
                  </Text>
                </HStack>

                <Divider />

                <HStack w={'full'} justifyContent={'center'} alignItems={'center'}>
                  <Icon as={LuZap} fontSize={'1.5rem'} color={Icon2Color} />
                  <Text fontSize={'lg'} color={bulletColor}>
                    {xp || 0} XP added
                  </Text>
                </HStack>
              </VStack>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PurchaseModal; 