import { useCallback, useEffect, useState } from 'react';
import { useUserContext } from '../providers/UserProvider';
import { getSubscriptionPlans } from '../services/subscriptionPlans.service.js';

export const useGetPlans = () => {
  const { token } = useUserContext();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAnnual, setIsAnnual] = useState(false);

  const getPlans = useCallback(async () => {
    if (token) {
      try {
        setLoading(true);
        const fetchedData = await getSubscriptionPlans(token);

        // Sort the plans by price (using the monthly price for consistency)
        const sortedPlans = fetchedData.sort((a, b) => {
          const aPrice = a.prices.find(p => p.interval === 'month')?.cost || 0;
          const bPrice = b.prices.find(p => p.interval === 'month')?.cost || 0;
          return aPrice - bPrice;
        });

        setPlans(sortedPlans);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }, [token]);

  useEffect(() => {
    getPlans();
  }, [getPlans]);

  const getPlanPrice = useCallback((plan) => {
    const priceObj = plan.prices.find(price => price.interval === (isAnnual ? 'year' : 'month'));
    return priceObj ? priceObj.cost : 0;
  }, [isAnnual]);

  const toggleInterval = useCallback(() => {
    setIsAnnual(prev => !prev);
  }, []);

  return { plans, loading, getPlans, isAnnual, getPlanPrice, toggleInterval };
};

export default useGetPlans;
